import React, { useState, useEffect } from "react";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import { FormattedMessage } from "react-intl";
import { Pagination } from "@mui/material";
import { toast } from "react-toastify";

import { useSelector, useDispatch } from "react-redux";
import { addSpinner, removeSpinner } from "../../redux/actions";
import { API, API_ROUTER } from "../../api/index";
import Modal from "./filterModal";

import s from "../../comon/ComonAdmin.module.scss";
import style from "./membersPage.module.scss";
//обнуляющие стили
import "../../comon/_null_style.css";

//компоненты, из которых состоит страница
import ProfilePanel from "../../components/AdminComponents/ProfilePanelComponent/index";
import HeaderAdmin from "../../components/AdminComponents/header-admin";
import Table from "../../comon/Table-component";
import InfoPanel from "../../components/AdminComponents/InfoPanel/index";
import SelectGroup from "./components/selectGroup";
import { Container } from "../../components/UI";

//const
import { pageInfo, titleHeader, headerItem } from "./const";
import { profileInfo, setPaginatorDate } from "../../comon/comonConst";
import BtnBan from "../../components/AdminComponents/btnBan";
import Checkbox from "../../components/AdminComponents/Checkbox";
import Paginator from "../../comon/Paginator";
import BanPopup from "./banPopup";
import NoAccessPopup from "./noAccessPopup";
import { stringify } from "querystring";

const MembersPage = (props) => {
  const dispatch = useDispatch();

  const [itemArray, setItemArray] = useState(null);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [data, setData] = useState([]);
  const [dataForFilter, setDataForFilter] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAccessPopup, setShowAccessPopup] = useState(false);
  const [showBanPopup, setShowBanPopup] = useState(false);
  const [memberToBan, setMemeberToBan] = useState(null);
  const [filterData, setFilter] = useState();
  const [updateData, setUpdateData] = useState(false);
  console.log(selectedItem);
  const handleChangePages = (event, value) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setPages(value);
  };

  // назначить верифицированным
  const changeValueVerified = (
    id,
    value,
    fnChange,
    communityId,
    fnPreloader
  ) => {
    fnPreloader(true);
    API.request({
      ...API_ROUTER.userAdmin.changeUserAdminVerified,
      data: {
        userId: id,
        newValue: value,
        communityId: communityId,
      },
    })
      .then((res) => {
        setUpdateData(!updateData);
        fnChange(value);
        fnPreloader(false);
        toast.success("verified change");
      })
      .catch((err) => {
        console.log(err);
        toast.error("error");
      });
  };
  // назначить про
  const changeValuePro = (id, value, fnChange, communityId, fnPreloader) => {
    fnPreloader(true);
    API.request({
      ...API_ROUTER.userAdmin.changeUserAdminIsPro,
      data: {
        userId: id,
        newValue: value,
        communityId: communityId,
      },
    })
      .then((res) => {
        fnChange(value);
        fnPreloader(false);
        toast.success("pro change");
      })
      .catch((err) => {
        console.log(err);
        toast.error("error");
      });
  };
  // дать бан
  const handleBanClick = (access, nickname, id) => {
    const haveAccess = access;
    if (haveAccess) {
      setShowBanPopup(true);
      setMemeberToBan({ nickname, id });
    } else {
      setShowAccessPopup(true);
    }
  };
  // фильтр по селекту
  const handleChange = (e) => {
    setPages(1);
    sessionStorage.setItem("selectedItem", JSON.stringify(e));
    setSelectedItem(e);
  };
  // получить данные
  const getUsers = (data) => {
    dispatch(addSpinner());

    let params = {
      ...API_ROUTER.userAdmin.getUserAdminPost,
      pathKeys: {
        page: pages,
        limit: 100,
      },
      data,
    };

    API.request(params)
      .then((res) => {
        setData(res.rows);
        setDataForFilter(res.rows);
        // разбиваем для пагинации
        setLastPage(res.paginator.lastPage);
        setCurrentPage(res.paginator.currentPage);

        dispatch(removeSpinner());
      })
      .catch((err) => {
        console.log(err);
        dispatch(removeSpinner());
      });
  };
  // получить данные для фильтра по селекту
  useEffect(() => {
    const storedItem = JSON.parse(sessionStorage.getItem("selectedItem"));
    setSelectedItem(storedItem);
  }, []);

  useEffect(() => {
    const sessionData = JSON.parse(sessionStorage.getItem("selectedItem"));
    let data = {};
    // если выбрана группа в селекте то берем ее, если нет то берем из сессии
    if (selectedItem) {
      data = {
        visibility: {
          [selectedItem?.type]: selectedItem?.value,
        },
        filter: filterData,
      };
    } else {
      data = {
        visibility: {
          [sessionData?.type]: sessionData?.value,
        },
        filter: filterData,
      };
    }

    getUsers(data);
  }, [selectedItem, pages, filterData, updateData]);
  return (
    <div className={s.evensPageContainer}>
      <ProfilePanel pageInfo={pageInfo} profileInfo={profileInfo} />
      <ContentContainer MainBlock={true} MainCreateBlock={null}>
        <Container>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              marginLeft: "10px",
            }}
          >
            <HeaderAdmin
              pageName={
                selectedItem ? (
                  <div
                    style={{
                      display: "flex",

                      fontSize: "20px",
                    }}
                  >
                    Members of
                    <p
                      style={{
                        color: "#b67e2a",
                        marginLeft: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {selectedItem?.label}
                    </p>
                  </div>
                ) : (
                  <p style={{ fontSize: "20px" }}>
                    Please choose community, event or tournament
                  </p>
                )
              }
              toROUTER={pageInfo.toROUTER}
              addBtn={pageInfo.addBtn}
              periodContainer={pageInfo.periodContainer}
              btnInfo={pageInfo?.btnInfo}
            />
            <Modal
              setItems={setData}
              data={dataForFilter}
              setFilter={setFilter}
            />
          </div>

          {showBanPopup && (
            <BanPopup
              open={showBanPopup}
              setOpen={setShowBanPopup}
              member={memberToBan}
              communityId={selectedItem.value}
            />
          )}
          {showAccessPopup && (
            <NoAccessPopup
              open={showAccessPopup}
              setOpen={setShowAccessPopup}
            />
          )}

          <SelectGroup
            handleChange={handleChange}
            selectedItem={selectedItem}
            defaultValue={JSON.parse(sessionStorage.getItem("selectedItem"))}
          />

          {selectedItem && data.length > 0 ? (
            <div className={s.mainScrollContainer}>
              <div className={s.scrollContainer}>
                <Table
                  bodyTitle={titleHeader}
                  infoItem={itemArray}
                  data={data}
                  selectedItem={selectedItem}
                  changeValueVerified={changeValueVerified}
                  changeValuePro={changeValuePro}
                  handleBanClick={handleBanClick}
                  setShowAccessPopup={setShowAccessPopup}
                />
              </div>
              <div className={style.paginatorWrapper}>
                <Pagination
                  classes={{ ul: style.pagination }}
                  count={lastPage}
                  page={currentPage}
                  onChange={handleChangePages}
                  variant="outlined"
                />
              </div>
            </div>
          ) : (
            <p
              style={{
                marginTop: 60,
                textAlign: "center",
                fontSize: 22,
                fontWeight: 500,
              }}
            >
              No members found
            </p>
          )}
        </Container>
      </ContentContainer>
    </div>
  );
};

export default MembersPage;
