import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { css } from "styled-components";
import classNames from "classnames";

import ErrorMessage from "../blocks/ErrorMessage";

export default function InfoInput({
  icon = "",
  placeholder = "Label",
  borderBottom = false,
  birthdate = false,
  textarea = false,
  error = "",
  label,
  showErrorMessage = true,
  disabled = false,
  language = "english",
  ...props
}) {
  const [v, setV] = useState("");
  const [errorMessage, setErrorMessage] = useState(error);

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  function changeV(e) {
    let { value } = e.target;
    if (birthdate) {
      var reg = /^\d+$/;
      value = value
        .split("")
        .filter((i) => i !== "-")
        .join("");
      console.log(value);
      if (!reg.test(value) && value !== "") return;

      let tempV = value.split("");
      if (tempV.length > 8) return;
      if (tempV.length > 1) {
        tempV.splice(2, 0, "-");
      }
      if (tempV.length > 4) {
        tempV.splice(5, 0, "-");
      }
      setV(tempV.join(""));
      return;
    }
    setV(value);
    if (language === "english") {
      value = value.replace(/[^A-Za-z]/gi, "");
      setV(value);
      return;
    }
  }

  function onBlur(e) {
    let { type, value, required } = e.target;
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

    if (required && !value) {
      setErrorMessage("This field is required");
      return;
    }

    if (type === "email") {
      if (!value.match(pattern)) {
        setErrorMessage(
          "Please enter provide email address. Ex : email@gmail.com"
        );
        return;
      }
    }

    setErrorMessage("");
  }

  return (
    <>
      <StyledInfoInput
        {...props}
        className={classNames("styledInputColor")}
        borderBottom={borderBottom}
      >
        <div>
          <i className={"icon icon-" + icon}></i>
        </div>
        <div>
          {textarea ? (
            <textarea
              placeholder={placeholder}
              value={v}
              onChange={changeV}
              onBlur={onBlur}
              rows={3}
              {...props}
            />
          ) : (
            <input
              placeholder={placeholder}
              disabled={disabled && disabled}
              value={v}
              onChange={changeV}
              onBlur={onBlur}
              {...props}
            />
          )}
        </div>
      </StyledInfoInput>
      <StyledError>
        {errorMessage && showErrorMessage && (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        )}
      </StyledError>
    </>
  );
}

const StyledInfoInput = styled.div`
  padding-top: 10px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-template-columns: 0px auto 100px;

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  ${({ borderBottom }) =>
    borderBottom &&
    css`
      ${"" /* border-bottom: 1px solid #9a9ca6; */}
    `}

  & > div {
    & > input,
    & > textarea {
      font-style: normal;
      // margin-top: 20px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.56);
      width: 300px;
      height: 48px;
      background: rgba(32, 25, 66, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.12);
      box-sizing: border-box;
      border-radius: 12px;
      padding: 12px 16px;
      margin-right: 16px;
    }

    &:first-of-type {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;

      & > i {
        width: 15px;
        height: 15px;
      }
    }
  }
`;

const StyledError = styled.div`
   {
    padding: 0px 38px;
  }
`;
