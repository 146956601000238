export const constformFields = (landingData) => {
  return {
    ...landingData,
    admissionProcess: "automatic" || "",
    description: landingData?.description || "",
    name: landingData?.name || "",
    nameOfOrganizer: landingData?.nameOfOrganizer || "",
    rules: landingData?.rules || "",
    socialNetworks: {
      discord: landingData?.socialNetworks?.discord || "",
      facebook: landingData?.socialNetworks?.facebook || "",
      instagram: landingData?.socialNetworks?.instagram || "",
      twitter: landingData?.socialNetworks?.twitter || "",
    },
    contact: landingData?.contact || [{ name: "", title: "" }],
    teamOfOrganizer: landingData?.teamOfOrganizer || [{ name: "", title: "" }],
  };
};
export const initialErrors = {
  nameOfOrganizer: "",
  name: "",
  description: "",
};
