export const data = {
   infoMainTableTitle: {
      titleHeader: ['Tournament title', 'EEF Europe Championship 2022 — Rocket League'],
      bodyTitle: {
         firstTitle: ['Tournament participants and results'],
         secondTitle: ['Tournament matches'],
      }
   },
   titleRowTable: {
      firstHeader: ['#', 'Team', 'Name', 'Nickname', 'Place', 'Country', 'Date Of Birth'],
      lastHeader: ['#', 'Tournament Stage', 'Participant 1', 'Participant 2', 'Result'],
   },
   infoItem: {
      headerItem: [
         ['Venue', 'Azerbaijan, Baku'],
         ['Tournament organizer', 'Azerbaijan Esports Federation'],
         ['Tournament start date', '25.05.2022'],
         ['Tournament end date', '28.05.2022'],
         ['Game and format', 'Rocket League 3v3'],
         ['Stage 1', 'Groups round robin'],
         ['Stage 2', 'Double elimination'],
      ],
      bodyFirstItem: [
         ['1', 'Netherlands', 'Justus Zielhuis', 'Justuszzz', '1', 'Netherlands', '09.10.2003'],
         ['2', 'Netherlands', 'Wouter Vrakking', 'Gekk', '1', 'Netherlands', '17.02.2003'],
         ['3', 'Netherlands', 'Tim Holland', 'TxMpeR', '1', 'Netherlands', '29.08.2004'],
         ['4', 'Sweden', 'Jakob Gowans', 'Gylfen', '2', 'Sweden', '03.01.2003'],
         ['5', 'Sweden', 'Jakob Gowans', 'Gylfen', '2', 'Sweden', '03.01.2003'],
         ['6', 'Sweden', 'Moltas Lindmark', 'Molten', '2', 'Sweden', '05.07.2004'],
         ['7', 'Spain', 'Pablo Torres Ruiz', 'TORRES8232', '3', 'Spain', '22.09.2004'],
         ['8', 'Spain', 'Guillermo Navarro Sanchis', 'Wilfred', '3', 'Spain', '28.06.2000'],
         ['9', 'Spain', 'Pablo Leon Briones', 'Leoro', '3', 'Spain', '26.06.2002'],
         ['10', 'Turkey', 'Bahadır Horuz', 'BHD', '4', 'Turkey', '28.01.1998'],
         ['11', 'Turkey', 'Arda Teker', 'Lorely', '4', 'Turkey', '21.05.2003'],
         ['12', 'Kaan Simsek', 'Psycho', 'Gylfen', '4', 'Turkey', '07.08.1996'],
         ['13', 'Slovakia', 'Martin Opálek', 'mrtn.', '5', 'Slovakia', '20.01.2002'],
         ['14', 'Slovakia', 'Miroslav Huba', 'SomeAway', '5', 'Slovakia', '28.11.2000'],
         ['15', 'Slovakia', 'Jakub Poruban', 'grieel', '5', 'Slovakia', '14.04.1999'],
         ['16', 'Hungary', 'Zoltán Bartha', 'CokeHUN', '5', 'Hungary', '07.03.2000'],
         ['17', 'Hungary', 'Richárd Yem', 'Richmond', '5', 'Hungary', '08.06.1998'],
         ['18', 'Hungary', 'Ádám Zubor', 'Gogi', '5', 'Hungary', '18.06.2003'],
         ['19', 'Wales', 'Ben Holt', 'Natsumi', '7', 'Wales', '30.06.2002'],
         ['20', 'Wales', 'Benjamin Rees', 'Delinquent', '7', 'Wales', '31.05.2002'],
         ['21', 'Wales', 'Bailey Griffiths-bater', 'X BATR', '7', 'Wales', '17.08.2000'],
         ['22', 'Luxembourg', 'Eric Hilger', 'Kuroi', '7', 'Luxembourg', '02.02.1994'],
         ['23', 'Luxembourg', 'Loris Centrone', 'LorisRaptor', '7', 'Luxembourg', '27.05.2002'],
         ['24', 'Luxembourg', 'Andy Di Domenico', 'Ultra_Prizze', '7', 'Luxembourg', '20.06.1998'],
         ['25', 'Azerbaijan', 'Seyyad Babayev', 'dragonghost', '9', 'Azerbaijan', '02.03.1992'],
         ['26', 'Azerbaijan', 'Rizvan Aliyev', 'Reezy', '9', 'Azerbaijan', '11.03.1997'],
         ['27', 'Azerbaijan', 'Shamil Babazade', 'Aira', '9', 'Azerbaijan', '06.03.2004'],
         ['28', 'Monaco', 'Romain Ducloux', 'Michcitys', '9', 'Monaco', '17.02.1998'],
         ['29', 'Monaco', 'Kenneth Gadeau', 'MC KenGad', '9', 'Monaco', '17.10.1996'],
         ['30', 'Monaco', 'Anthony Ducloux', 'Crunck17', '9', 'Monaco', '17.02.1998'],
      ],
      bodyLastItem: [
         ['1', 'Stage 1 - Groups rounds robin - Group A', 'Sweden', 'Hungary', '2-0'],
         ['2', 'Stage 1 - Groups rounds robin - Group A', 'Turkey', 'Netherlands', '0-2'],
         ['3', 'Stage 1 - Groups rounds robin - Group B', 'Wales', 'Slovakia', '1-2'],
         ['4', 'Stage 1 - Groups rounds robin - Group B', 'Monaco', 'Luxembourg', '0-2'],
         ['5', 'Stage 1 - Groups rounds robin - Group A', 'Sweden', 'Netherlands', '2-1'],
         ['6', 'Stage 1 - Groups rounds robin - Group A', 'Azerbaijan', 'Turkiye', '0-2'],
         ['7', 'Stage 1 - Groups rounds robin - Group B', 'Spain', 'Slovakia', '2-0'],
         ['8', 'Stage 1 - Groups rounds robin - Group B', 'Wales', 'Monako', '2-0'],
         ['9', 'Stage 1 - Groups rounds robin - Group A', 'Hungary', 'Netherlands', '0-2'],
         ['10', 'Stage 1 - Groups rounds robin - Group A', 'Sweden', 'Azerbaijan', '2-0'],
         ['11', 'Stage 1 - Groups rounds robin - Group B', 'Monaco', 'Spain', '0-1'],
         ['12', 'Stage 1 - Groups rounds robin - Group B', 'Luxembourg', 'Wales', '0-2'],
         ['13', 'Stage 1 - Groups rounds robin - Group A', 'Azerbaijan', 'Hungary', '0-2'],
         ['14', 'Stage 1 - Groups rounds robin - Group A', 'Turkiye', 'Sweden', '1-2'],
         ['15', 'Stage 1 - Groups rounds robin - Group B', 'Monaco', 'Slovakia', '0-2'],
         ['16', 'Stage 1 - Groups rounds robin - Group B', 'Spain', 'Luxembourg', '2-0'],
         ['17', 'Stage 1 - Groups rounds robin - Group A', 'Hungary', 'Turkiye', '2-0'],
         ['18', 'Stage 1 - Groups rounds robin - Group A', 'Netherlands', 'Azerbaijan', '2-0'],
         ['19', 'Stage 1 - Groups rounds robin - Group B', 'Wales', 'Spain', '0-2'],
         ['20', 'Stage 1 - Groups rounds robin - Group B', 'Luxembourg', 'Slovakia', '1-2'],
         ['21', 'Stage 2 – Double elimination', 'Sweden', 'Luxembourg', '3-0'],
         ['22', 'Stage 2 – Double elimination', 'Slovakia', 'Hungary', '1-3'],
         ['23', 'Stage 2 – Double elimination', 'Spain', 'Turkiye', '3-0'],
         ['24', 'Stage 2 – Double elimination', 'Netherlands', 'Wales', '3-0'],
         ['25', 'Stage 2 – Double elimination', 'Turkiye', 'Wales', '3-1'],
         ['26', 'Stage 2 – Double elimination', 'Luxembourg', 'Slovakia', '2-3'],
         ['27', 'Stage 2 – Double elimination', 'Sweden', 'Hungary', '3-1'],
         ['28', 'Stage 2 – Double elimination', 'Spain', 'Netherlands', '0-3'],
         ['29', 'Stage 2 – Double elimination', 'Spain', 'Slovakia', '3-1'],
         ['30', 'Stage 2 – Double elimination', 'Hungary', 'Turkiye', '2-3'],
         ['31', 'Stage 2 – Double elimination', 'Sweden', 'Netherlands', '1-3'],
         ['32', 'Stage 2 – Double elimination', 'Spain', 'Turkiye', '3-2'],
         ['33', 'Stage 2 – Double elimination', 'Spain', 'Sweden', '2-3'],
         ['34', 'Stage 2 – Double elimination', 'Netherlands', 'Sweden', '4-0'],
      ]
   }
}