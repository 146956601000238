import React, { useRef, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDraggable } from "react-use-draggable-scroll";
import { useSelector } from "react-redux";
import Checkbox from "../../components/AdminComponents/Checkbox";

import MoreInfoMemeberModal from "./modal";
import { CheckBox } from "../../components/UI";
import EyeIcon from "./img/eyeIcon";

import s from "./table.module.scss";

const Table = ({
  data,
  selectedItem,
  changeValueVerified,
  changeValuePro,
  handleBanClick,
  setShowAccessPopup,
}) => {
  const dragRef = useRef();
  const { events } = useDraggable(dragRef);
  const countriesList = useSelector((state) => state.countriesList);

  const [openModal, setOpenModal] = useState(false);
  const [userGames, setUserGames] = useState([]);
  const [userNick, setUserNick] = useState("");
  const [verified, setVerified] = useState(false);
  const [pro, setPro] = useState(false);
  const [ban, setBan] = useState(false);
  console.log(data);
  function getCountryFromValue(country) {
    const fullCountry = countriesList.find((item) => item?.value === country);
    return fullCountry?.label;
  }
  const isEmptyField = (field) => {
    return field ? field : <div style={{ color: "gray" }}>empty</div>;
  };
  return (
    <div {...events} ref={dragRef} className={s.detailWrap}>
      <div className={s.scrollWrap}>
        <div className={s.tableWrap}>
          <table className={s.table}>
            <caption>MATCH MEMBERS FFA</caption>

            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage
                    id="members.title.nickname"
                    tagName="span"
                  />
                </th>
                <th scope="col">
                  <FormattedMessage
                    id="members.title.firstName"
                    tagName="span"
                  />
                </th>
                <th scope="col">
                  <FormattedMessage
                    id="members.title.lastName"
                    tagName="span"
                  />
                </th>
                <th scope="col">
                  <FormattedMessage id="members.title.country" tagName="span" />
                </th>
                <th scope="col">
                  <FormattedMessage id="members.title.games" tagName="span" />
                </th>
                <th scope="col">
                  <FormattedMessage
                    id="members.title.rigistration"
                    tagName="span"
                  />
                </th>
                <th scope="col">
                  <FormattedMessage
                    id="members.title.verified"
                    tagName="span"
                  />
                </th>
                <th scope="col">
                  <FormattedMessage id="members.title.pro" tagName="span" />
                </th>
                <th scope="col">
                  <FormattedMessage id="members.title.banned" tagName="span" />
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((el) => (
                <tr>
                  <td className={s.memberUrl}>
                    <a href={`https://app.passport.gg/id/${el.url}`}>
                      {isEmptyField(el.nickname)}{" "}
                    </a>
                  </td>
                  <td>{isEmptyField(el.firstName)}</td>
                  <td>{isEmptyField(el.lastName)}</td>
                  <td>{isEmptyField(getCountryFromValue(el.country))}</td>
                  <td>
                    <button
                      className={s.btn}
                      onClick={(e) => {
                        setOpenModal(!openModal);
                        setUserGames(el.userGames);
                        setUserNick(el.nickname);
                      }}
                    >
                      <EyeIcon />
                    </button>
                  </td>
                  <td>{isEmptyField(el.registrationDate)}</td>
                  <td>
                    <div className={s.checkboxContainer}>
                      <Checkbox
                        userId={el.id}
                        onChange={changeValueVerified}
                        isActiveBack={el.verified}
                        access={selectedItem?.communityAdvanced}
                        showAccessPopup={setShowAccessPopup}
                        communityId={selectedItem?.value}
                      />
                    </div>
                  </td>
                  <td>
                    <div className={s.checkboxContainer}>
                      <Checkbox
                        userId={el.id}
                        isActiveBack={el.pro}
                        onChange={changeValuePro}
                        access={selectedItem?.communityAdvanced}
                        showAccessPopup={setShowAccessPopup}
                        communityId={selectedItem?.value}
                      />
                    </div>
                  </td>
                  <td>
                    <div className={s.checkboxContainer}>
                      <button
                        className={el.banned ? s.btnBanned : s.btnBan}
                        onClick={() =>
                          handleBanClick(
                            selectedItem?.communityAdvanced,
                            el.nickname,
                            el.id
                          )
                        }
                      >
                        {el.banned ? "Banned" : "Ban"}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <MoreInfoMemeberModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        userGames={userGames}
        userNick={userNick}
      />
    </div>
  );
};
export default Table;
