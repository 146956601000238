import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROUTER } from "../../config";
import { Container } from '../UI';
import HeaderNav from './header-nav';
import HeaderSearch from "./search";
import HeaderNavButton from "./HeaderNavButton";
import HeaderUserPanel from './HeaderUserPanel';
import logoLG from '../../img/logo-lg.png';
import logoSM from '../../img/logo-sm.png';
import { ReactComponent as LogoutIcon } from './icons/login.svg';
import { FormattedMessage } from "react-intl";

import { API, API_ROUTER } from '../../api'
import store from '../../redux/store'
import { userOffline, setUserData } from '../../redux/actions';
import hogLogo from './icons/hog.png'
import { isHOGDomain } from '../../comon/isHOGDomain';

const StyledHeader = styled.header`
        background: ${props => (props.hog ? '#1e181e' : '#201941')};
        margin: 0 0 10px;
        position: relative;
    `,
    StyledContainer = styled(Container)`
        width: 66%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;
        @media (max-width: 1730px) {
            width: 60%;
         }
        @media (min-width: 768px) {
            height: 115px;
        }
    `,
    StyledLogo = styled.a`
        display: ${props => props.navStatus ? "none" : "block"};
        position: absolute;
        top: calc (50%-20px);
        left: 7px;
        
        .logo {
            margin-left: 25px;
            img {
                display: block;
                max-height: 40px;
                max-width: 234px;

                @media (max-width: 767px) {
                    max-height: 50px;
                    max-width: 74px;
                }
            }

            &-sm {
                display: none;

                @media (max-width: 767px) {
                    display: block;
                }
            }

            &-lg {
                display: block;

                @media (max-width: 767px) {
                    display: none;
                }
            }
            }
    `,
    StyledControls = styled.div`
        display: flex;
        justify-content: flex-end;
        align-items: ${props => props.navStatus ? "flex-start" : "center"};
        width: 100%;

        @media (min-width: 768px) {
            align-items: center;
        }
    `;

const Header = ({ history, match }) => {
    const [navState, setNavState] = useState(false);
    const isUserOnline = useSelector(state => state.userOnline);
    const toHomepage = e => {
        e.preventDefault();
        history.push(isUserOnline ? '/admin/dashboard' : ROUTER.homepage);
    };

    if (window.location.pathname === ROUTER.homepage) return null;

    const logOut = (e, history) => {
        e.preventDefault();

        const params = {
            ...API_ROUTER.auth.logOut
        };

        API.request(params, true)
            .then(() => {
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('refreshToken');
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                store.dispatch(userOffline());
                store.dispatch(setUserData(null));
                // navigate(ROUTER.homepage)
                history.push(ROUTER.homepage);
            })
            .catch(err => console.log(err))
    };

    return (
        <StyledHeader hog={isHOGDomain()}>
            <StyledContainer>

                {isHOGDomain() ?
                    <div style={{
                        position: 'absolute', left: '10px'
                    }}>
                        <a href='https://app.hogasia.com'>
                            <img style={{ width: 200, height: 100 }} src={hogLogo} alt="hog logo" />
                        </a>
                        <p style={{
                            marginTop: '-22px',
                            fontSize: '12px',
                            marginLeft: 29,
                            color: '#d7bc33',
                        }}>Powered by <a href='https://passport.gg' style={{ color: '#d7bc33', cursor: 'pointer' }}>Passport.gg</a></p>
                    </div> : <StyledLogo href="/" navStatus={navState} onClick={toHomepage}>
                        <div className="logo logo-sm">
                            <img src={logoSM} alt="passport.gg" />
                        </div>
                        <div className="logo logo-lg">
                            <img src={logoLG} alt="passport.gg" />
                        </div>
                    </StyledLogo>}
                <div onClick={e => logOut(e)} style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', cursor: 'pointer' }}>
                    <LogoutIcon
                        style={{
                            fill: `${isHOGDomain() ? '#d7bc33' : 'white'}`,
                            stroke: `${isHOGDomain() ? '#d7bc33' : 'white'}`,
                        }}
                    />
                    <a style={{
                        marginLeft: 8,
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 14,
                        letterSpacing: '0.05em',
                        color: `${isHOGDomain() ? '#d7bc33' : 'white'}`,
                        textDecoration: 'none',
                    }} href='/'>
                        <FormattedMessage id="settings.nav.logout" /></a>
                </div>
                {/* <StyledControls navStatus={navState}> */}
                {/* <HeaderSearch /> */}
                {/* <HeaderNav navStatus={navState} /> */}
                {/* <HeaderUserPanel navStatus={navState} /> */}
                {/* <HeaderNavButton navStatus={navState} clickHandler={setNavState} /> */}
                {/* </StyledControls> */}
            </StyledContainer >
        </StyledHeader >
    )
};

export default withRouter(Header);

