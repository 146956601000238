import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Select from "react-select";
import logo from "../../../img/MainCreateBlock/Upload_Logo.png";
import { API, API_ROUTER } from "../../../api/index";
import { addSpinner, removeSpinner } from "../../../redux/actions";
import { showNotificationModal } from "../../../redux/actions";

import { isHOGDomain } from "../../../comon/isHOGDomain";
import styles from "./newPerson.module.scss";
export const ROLES = [
  { label: "admin", value: "admin" },
  { label: "manager", value: "manager" },
  { label: "referee", value: "judge" },
];

export const TYPES = [
  { id: 1, name: "community" },
  { id: 2, name: "event" },
  { id: 3, name: "tournament" },
];

export const pageInfo = {
  logo: logo,
};

const AddNewPerson = ({ selectedItem, setUpdateData, updateData }) => {
  const dispatch = useDispatch();
  const [inputValue, setinputValue] = useState("");
  const [roleValue, setRoleValue] = useState("");
  const validationEmpty = (element) => {
    if (element?.length < 1) {
      return `${styles.inputErr}`;
    }
  };
  const whereRoleGiven = () => {
    if (selectedItem?.type === "event") {
      return "eventId";
    } else if (selectedItem?.type === "tournament") {
      return "tournamentId";
    } else if (selectedItem?.type === "community") {
      return "communityId";
    }
  };
  const wichRole = (role) => {
    if (selectedItem?.type === "event") {
      return "eventRole";
    } else if (selectedItem?.type === "tournament") {
      return "tournamentRole";
    } else if (selectedItem?.type === "community") {
      return "communityRole";
    }
  };
  const onSubmitForm = (inputValue, roleValue) => {
    const userIdOrEmail = inputValue.includes("@")
      ? { userEmail: inputValue }
      : { userId: inputValue };
    const data = {
      ...userIdOrEmail,
      [whereRoleGiven()]: selectedItem?.value,
      [wichRole()]: roleValue,
    };
    console.log(data);

    let params = {
      ...API_ROUTER.userAdmin.setAccessRights,
      data,
    };
    console.log("params", params);
    if (inputValue && roleValue) {
      dispatch(addSpinner());
      API.request(params)
        .then((res) => {
          dispatch(
            showNotificationModal(
              ` User ${inputValue} has been successfully added to the ${selectedItem?.type}`
            )
          );
          setUpdateData(!updateData);
          dispatch(removeSpinner());
        })
        .catch((err) => {
          console.log(err.data);
          dispatch(removeSpinner());
          dispatch(showNotificationModal(err.data.message));
        });
    } else {
      toast.error(`Please fill user and choose role`);
    }
  };

  const userPlaceholder = useIntl().formatMessage({
    id: "roleManagement.title.user.placeholder",
  });

  return (
    <div className={styles.mainBlock} style={{ marginTop: 30 }}>
      <div className={styles.inputContainer}>
        <div
          className={styles.text}
          style={{ marginBottom: 10, color: "#b67e2a" }}
        >
          <FormattedMessage id="roleManagement.title.user" tagName="span" />{" "}
        </div>

        <input
          type="text"
          placeholder={userPlaceholder}
          className={validationEmpty(inputValue)}
          onChange={(e) => setinputValue(e.target.value)}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ marginBottom: 30 }}>
          <div className={styles.selectContainer}>
            <div
              className={styles.text}
              style={{ marginBottom: 10, color: "#b67e2a" }}
            >
              <FormattedMessage id="roleManagement.title.role" tagName="span" />
            </div>

            <div>
              <div
                className={styles.select}
                style={{ background: `${isHOGDomain() && "#846414"}` }}
              >
                <Select
                  required
                  style={{ fontSize: 18 }}
                  classNamePrefix="custom_select"
                  options={ROLES || []}
                  onChange={(e) => setRoleValue(e.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.contentHeaderTitleContainer}>
        <button
          type="submit"
          onClick={(e) => onSubmitForm(inputValue, roleValue)}
          className={styles.createBtn}
          style={{ textAlign: "center" }}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddNewPerson;
