import React, { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useDispatch } from "react-redux";

import { showNotificationModal } from "../../../../redux/actions";

import { API, API_ROUTER } from "../../../../api/index";

import Checkbox from "../../../../components/AdminComponents/Checkbox";

import s from "./table.module.scss";

const Table = ({
  data,
  selectedItem,
  setShowAccessPopup,
  setUpdateData,
  updateData,
}) => {
  const dragRef = useRef();
  const { events } = useDraggable(dragRef);
  const dispatch = useDispatch();

  // куда пользователь получает роль в зависимости от типа события
  const whereRoleGiven = () => {
    if (selectedItem?.type === "event") {
      return "eventId";
    } else if (selectedItem?.type === "tournament") {
      return "tournamentId";
    } else if (selectedItem?.type === "community") {
      return "communityId";
    }
  };
  // какую роль получает пользователь в зависимости от типа события
  const wichRole = (role) => {
    if (selectedItem?.type === "event") {
      return "eventRole";
    } else if (selectedItem?.type === "tournament") {
      return "tournamentRole";
    } else if (selectedItem?.type === "community") {
      return "communityRole";
    }
  };
  // активировать чекбокс в зависимости от роли
  const isChecked = (role, row) => {
    if (row === "admin") {
      return role === "admin" || role === "owner" ? true : false;
    } else if (row === "manager") {
      return role === "manager" || role === "admin" || role === "owner"
        ? true
        : false;
    } else if (row === "referee") {
      return role === "judge" ||
        role === "admin" ||
        role === "manager" ||
        role === "owner"
        ? true
        : false;
    } else {
      return false;
    }
  };
  // проверка на владельца
  const isOwner = (role) => {
    return role === "owner" ? true : false;
  };

  // функция для изменения роли
  const changeRole = (userId, roleId, roleGiven, userName) => {
    const data = {
      userId: userId,
      [whereRoleGiven()]: roleId,
      [wichRole()]: roleGiven,
    };
    let params = {};
    params = {
      ...API_ROUTER.userAdmin.setAccessRights,
      data,
    };

    API.request(params)
      .then((res) => {
        dispatch(
          showNotificationModal(
            `New role for user ${userName} has been successfully created`
          )
        );
        setUpdateData(!updateData);
      })
      .catch((err) => {
        console.log(err.data);
        dispatch(showNotificationModal(err.data.message));
      });
  };
  // функция для удаления пользователя (можно обьединить с функцией выше)
  const handleDelete = (userId, roleId, currentRole, userName) => {
    const data = {
      userId: userId,
      [whereRoleGiven()]: roleId,
      [wichRole()]: currentRole,
    };

    const params = {
      ...API_ROUTER.userAdmin.removeAccessRights,
      data,
    };
    console.log("params", params);
    API.request(params)
      .then((res) => {
        dispatch(
          showNotificationModal(
            `User ${userName} has been successfully deleted`
          )
        );
        setUpdateData(!updateData);
      })
      .catch((err) => {
        dispatch(showNotificationModal(err.data.message));
      });
  };

  return (
    <div {...events} ref={dragRef} className={s.detailWrap}>
      <div className={s.scrollWrap}>
        <div className={s.tableWrap}>
          <table className={s.table}>
            <caption>MATCH MEMBERS FFA</caption>

            <thead>
              <tr>
                <th scope="col">
                  <span>Email</span>
                </th>
                <th scope="col">
                  <span>Admin</span>
                </th>
                <th scope="col">
                  <span>Manager</span>
                </th>
                <th scope="col">
                  <span>Referee</span>
                </th>
                <th scope="col">
                  <span>delete</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((el) => (
                <tr>
                  <td>
                    {el.role === "owner" ? (
                      <>
                        <div> {el.userUsername}</div>{" "}
                        <div style={{ color: "#b67e2a" }}>OWNER</div>
                      </>
                    ) : (
                      el.userUsername
                    )}
                  </td>
                  <td>
                    <div className={s.checkboxContainer}>
                      <Checkbox
                        userId={el.userId}
                        onChange={changeRole}
                        isActiveBack={isChecked(el.role, "admin")}
                        roleId={selectedItem?.value}
                        access={true}
                        showAccessPopup={setShowAccessPopup}
                        communityId={selectedItem?.value}
                        rolesManagement={true}
                        roleGiven={"admin"}
                        currentRole={el.role === "admin" ? "admin" : "noRole"}
                        userName={el.userUsername}
                        owner={isOwner(el.role)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className={s.checkboxContainer}>
                      <Checkbox
                        userId={el.userId}
                        onChange={changeRole}
                        isActiveBack={isChecked(el.role, "manager")}
                        roleId={selectedItem?.value}
                        access={true}
                        showAccessPopup={setShowAccessPopup}
                        communityId={selectedItem?.value}
                        rolesManagement={true}
                        roleGiven={"manager"}
                        currentRole={
                          el.role === "manager" ? "manager" : "noRole"
                        }
                        userName={el.userUsername}
                        owner={isOwner(el.role)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className={s.checkboxContainer}>
                      <Checkbox
                        userId={el.userId}
                        onChange={changeRole}
                        isActiveBack={isChecked(el.role, "referee")}
                        roleId={selectedItem?.value}
                        access={true}
                        showAccessPopup={setShowAccessPopup}
                        communityId={selectedItem?.value}
                        rolesManagement={true}
                        roleGiven={"judge"}
                        currentRole={el.role === "judge" ? "judge" : "noRole"}
                        userName={el.userUsername}
                        owner={isOwner(el.role)}
                      />
                    </div>
                  </td>
                  <td>
                    <button
                      onClick={() =>
                        handleDelete(
                          el.userId,
                          selectedItem?.value,
                          el.role,
                          el.userUsername
                        )
                      }
                      style={{
                        width: 100,
                        background: "transparent",
                        marginTop: 10,
                      }}
                      type="button"
                    >
                      <DeleteOutlinedIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default Table;
