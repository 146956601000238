import React, { useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { loaderTexts } from '../loaderTexts';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        background: 'rgba(32,25,66, 0.9)',
        display: 'flex',
        flexDirection: 'column'
    },
}));

const Loader = ({ isOpen }) => {
    const classes = useStyles();
    const [shownText, setShownText] = useState(loaderTexts[0]);

    const changeTexts = (texts, step) => {
        let i = step
        setTimeout(() => {
            setShownText(texts[i])
            if (texts.length - 1 > step) {
                changeTexts(texts, step + 1)
            } else {
                changeTexts(texts, 0)
            }
        }, 5000)
    }

    useEffect(() => {
        changeTexts(loaderTexts, 0)
    }, [])
    return (
        <Backdrop className={classes.backdrop} open={isOpen} >
            <div style={{ marginBottom: '20px' }}>
                <CircularProgress />
            </div>
            <div style={{ margin: '0 auto', fontSize: 22, fontWeight: 500, width: 450, textAlign: 'start' }}>
                <p style={{ marginBottom: 18, textAlign: 'center' }}>Did you know that...</p>
                <p style={{ fontSize: 20 }}>{shownText}</p>
            </div>
        </Backdrop>
    )
}

export default Loader