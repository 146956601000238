import InputComponent from "../components/input";
import * as images from "../images";

const buttons = (
  ref,
  formFields,
  inputHandler,
  selectLanguages,
  changeLanguagesValue,
  errorField
) => {
  return (
    <>
      {" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockButtons1}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockButtons1"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.buttons1"
        errorField={errorField}
        requiredValue={false}
        tooltipImage={images.buttons1}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockButtons2}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockButtons2"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.buttons2"
        errorField={errorField}
        requiredValue={false}
        tooltipImage={images.buttons2}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockButtons3}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockButtons3"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.buttons3"
        errorField={errorField}
        requiredValue={false}
        tooltipImage={images.buttons3}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockButtons4}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockButtons4"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.buttons4"
        errorField={errorField}
        requiredValue={false}
        tooltipImage={images.buttons4}
      />{" "}
      <InputComponent
        ref={ref}
        element={formFields?.textBlockButtons5}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockButtons5"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId="createLanding.title.buttons5"
        errorField={errorField}
        requiredValue={false}
        tooltipImage={images.buttons5}
      />
    </>
  );
};

export default buttons;
