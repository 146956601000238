import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

import TooltipImg from "./tooltip";
import { isHOGDomain } from "../../../../comon/isHOGDomain";

import s from "../createLanding.module.scss";

const TextArea = React.forwardRef(
  (
    {
      // Элемент основного языка
      element,
      // Функция обработчик изменения элемента основного языка
      inputHandler,
      // Массив языков
      selectLanguages,
      // Элемент языка в виде строки
      selectLanguagesElement,
      // Функция обработчик изменения элемента языка
      changeLanguagesValue,
      // id для форматированного сообщения
      formattedMessageInputId,
      errorField,
      tooltipImage,
    },
    ref
  ) => {
    const validationEmpty = (element) => {
      if (element?.length < 1) {
        return `${s.textarea} ${s.textareaErr}`;
      } else {
        return `${s.textarea}`;
      }
    };
    return (
      <>
        {/* Основной блок текста */}

        <div
          className={s.titleContent}
          ref={errorField === selectLanguagesElement ? ref : null}
        >
          <div style={{ position: "relative" }}>
            <div className={s.titleInput}>
              <FormattedMessage
                id={`${formattedMessageInputId}`}
                tagName="span"
              />{" "}
              {" " + "EN*"}
            </div>
            <textarea
              style={{
                border: `${isHOGDomain() && "1px solid #846414"}`,
                background: `${isHOGDomain() && "transparent"}`,
              }}
              className={validationEmpty(element)}
              value={element}
              onChange={(e) => {
                inputHandler(e);
                validationEmpty(e);
              }}
              name={selectLanguagesElement}
            />{" "}
            <TooltipImg image={tooltipImage} />
          </div>

          {/* Цикл по selectLanguages в поисках selectLanguagesElement */}

          {selectLanguages?.map((el, i) => (
            <div key={i} className={s.secondTextContainer}>
              <div style={{ position: "relative" }}>
                <div className={s.titleInput}>
                  <FormattedMessage
                    id={`${formattedMessageInputId}`}
                    tagName="span"
                  />{" "}
                  {" " + el.name + "*"}
                </div>

                <textarea
                  style={{ background: `${isHOGDomain() && "#846414"}` }}
                  className={validationEmpty(el[selectLanguagesElement])}
                  defaultValue={el[selectLanguagesElement]}
                  onChange={(e) =>
                    changeLanguagesValue(
                      e.target,
                      el.id,
                      selectLanguagesElement
                    )
                  }
                  name={selectLanguagesElement}
                />
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
);

export default TextArea;
