
import React, { useEffect, useState } from "react";
import { default as ReactSelect } from "react-select";
import s from './SelectWithCheckboxes.module.scss'
import '../../comon/CustomSelect/select.css';
import { components } from "react-select";
import {isHOGDomain} from "../isHOGDomain";

const Option = (props) => {
    return (
        <div style={{background: isHOGDomain() ? "#000" : ""}}>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default function SelectWithCheckboxes(props) {
    const [optionSelected, setOptionSelected] = useState(null)

    const handleChange = (selected) => {
        console.log(selected);
        setOptionSelected(selected)
        props.setState(selected)
    };

    useEffect(() => {
        if (props.value?.length) {
            setOptionSelected(props.value)
        }
    }, [props.value?.length])

    const games = props.placeholder === 'Games'

    return (
        <div
            className={s.select} style={{background: isHOGDomain() && "rgb(132, 100, 20)"}}
        >
            <ReactSelect
                disabled={props.disabled}
                classNamePrefix='custom_select'
                placeholder={props.placeholder}
                options={props.options}
                isMulti={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                    Option
                }}
                value={optionSelected}
                getOptionLabel={(option) => games ? option.title : option.name}
                getOptionValue={(option) => option.id}
                onChange={handleChange}
                allowSelectAll={true}
            />
        </div>
    );
}
