import React, { useEffect, useState } from 'react';

import Select, { components } from 'react-select';
import { API, API_ROUTER } from '../../../api/index';
import {isHOGDomain} from "../../../comon/isHOGDomain";

const groupStyles = {
    border: `2px dotted '#5243AA'`,
    color: 'white',
    background: '#5243AA',
    padding: '5px 0px',
    display: 'flex',
};

const groupHOGStyles = {
    border: `2px dotted '#5243AA'`,
    background: 'rgba(215, 188, 51, 0.5)',
    color: '#d7bc33',
    padding: '5px 0px',
    display: 'flex',
};

const styles = {
    control: (css) => ({ ...css, backgroundColor: 'transparent' }),
    menu: (css) => ({ ...css, backgroundColor: isHOGDomain() ? '#000' : '#3F317C' }),
    singleValue: (css) => ({
        ...css, color: 'white'
    }),
    input: (css) => ({ ...css, color: 'white' }),
    option: (styles, { data }) => { return { ...styles, backgroundColor: 'transparent', marginLeft: data.margin } },
    multiValue: (css) => ({ ...css, backgroundColor: 'transparent' })
};

const GroupHeading = (
    props
) => (
    <div style={isHOGDomain() ? groupHOGStyles : groupStyles}>
        <components.GroupHeading {...props} />
    </div>
);

const GamesSelect = ({ handleChange, placeholder, value, isMulti, disabled }) => {

    const [games, setGames] = useState([])
    const [groupedOptions, setGroupedOptions] = useState([])

    useEffect(() => {
        API.request({
            ...API_ROUTER.user.getCurrentGames,
        })
            .then((res) => {
                setGames(res);
            })
            .catch((err) => console.log(err));
    }, [])


    const buildGamesDependencyArr = (games) => {
        const unique = [...new Set(games?.games?.map(item => item.groupName))]
        const arr = unique.map((item) => {
            const gamesByGroup = {
                label: item,
                expanded: false,
                options: []
            }
            games.games.forEach((game) => {
                if (item === game.groupName && game.status === 'active') {
                    const option = {
                        label: game.title,
                        value: game.id
                    }
                    gamesByGroup.options.push(option)
                }
            })
            return gamesByGroup
        })
        setGroupedOptions(arr)
    }

    useEffect(() => {
        buildGamesDependencyArr(games)
    }, [games])

    return (
        <Select
            disabled={disabled}
            onChange={(e) => handleChange(e)}
            placeholder={placeholder || "Select game"}
            options={groupedOptions}
            components={{ GroupHeading }}
            value={value}
            isMulti={isMulti}
            styles={{
                groupHeading: (base) => ({
                    ...base,
                    flex: '1 1',
                    color: 'white',
                    margin: 0,
                }),
                ...styles
            }}
        />
    )
};

export default GamesSelect
