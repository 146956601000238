import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import FileUploaderSingle from "../../../comon/file-uploader-for-single";
import SelectWithCheckboxes from "../../../comon/SelectWithCheckboxes";
import Select from "react-select";
import Sortable from "../../../comon/SortableList";
import GamesSelect from "./gamesSelect";
import { Container } from "../../../components/UI";
import { API, API_ROUTER } from "../../../api/index";
import { ROUTER } from "../../../config";
import { convertDateToString } from "../../../comon/comonConst";
import {
  EVENT_TYPES,
  GENDER,
  SYSTEM,
  FORMAT,
  PLATFORM,
  AFRICA_LAYERS,
  LAYERS,
} from "./const";
import {
  uploadData,
  onChangeDudlePhotos,
  addPhotos,
  delPhoto,
} from "../../../comon/uploadData";
import { handleDelete, addInputs, onChangeDudleInput } from "./helpers/forms";
import {
  addSpinner,
  removeSpinner,
  showNotificationModal,
} from "../../../redux/actions";
import { currentDate } from "../../../helpers/date/getCurrentDate";
import {
  createStages,
  createScoresForPlaces,
  createDirectSlots,
  createSecondChanceSlots,
  createSystemOptions,
  createFormat,
  checknumOfParticipantsInGroup,
} from "./helpers/data";

import s from "./createTournament.module.scss";
import "../../../comon/CustomSelect/select.scss";
import { uploadPhotos } from "./helpers/uploadPhotos";
import { DateInput } from "../../UI";
import classNames from "classnames";
import moment from "moment";
import { isHOGDomain } from "../../../comon/isHOGDomain";

const CreateTournament = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { id: editId } = useParams();
  const userData = useSelector((state) => state.userData);
  const {
    setValue,
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const [tournamentToEdit, setTournamentToEdit] = useState({});
  const [eventsBack, setEventsBack] = useState([]);
  const [communitysBack, setCommunitysBack] = useState([]);
  // const [siteType, setSiteType] = useState('')
  const [layersOptions, setLayesOptions] = useState([]);
  const [communityIdValue, setCommunityIdValue] = useState();
  const [countryOptions, setCountryOptions] = useState([]);
  const [community, setCommunity] = useState("");
  const [tabsForm, setTabsForm] = useState("description");
  const tabHandler = (tab) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTabsForm(tab);
  };
  const getCommunities = () => {
    let params = {
      ...API_ROUTER.community.getCommunities,
      pathKeys: {
        page: 1,
        limit: 9999,
      },
    };

    API.request(params)
      .then((res) => {
        setCommunitysBack(res.communities);
      })
      .catch((err) => console.log(err));
  };

  const getUserRights = () => {
    let params = {
      ...API_ROUTER.user.getUserRights,
    };
    API.request(params)
      .then((res) => {
        if (res.adminSites.includes("africa")) {
          setLayesOptions(AFRICA_LAYERS);
        } else {
          setLayesOptions(LAYERS);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCurrentGames = () => {
    API.request({
      ...API_ROUTER.user.getCurrentGames,
    })
      .then((res) => {
        setGamesFromBack(res);
      })
      .catch((err) => console.log(err));
  };

  const getCommunityEvents = () => {
    if (community?.id || communityIdValue) {
      let params = {
        ...API_ROUTER.community.getCommunityEvents,
        pathKeys: {
          page: 1,
          limit: 9999,
          communityId: community?.id || communityIdValue,
        },
      };
      API.request(params)
        .then((res) => {
          let newArr = [{ name: null }, ...res.events];
          setEventsBack(newArr);
        })
        .catch((err) => console.log(err));
    }
  };

  const getTournamentById = () => {
    if (editId) {
      setLoading(true);
      let params = {
        ...API_ROUTER.Tournaments.getTournamentById,
        pathKeys: {
          tournamentId: editId,
        },
      };
      dispatch(addSpinner());
      API.request(params)
        .then((res) => {
          setCommunityIdValue(res?.community?.id);
          if (currentDate() > res.registrationStartedAt) {
            dispatch(showNotificationModal(ServerAnswerSuccessDisableEdit));
            history.push(ROUTER.tournamentsPage);
            dispatch(removeSpinner());
          } else {
            const size = res.size.split("x").join("v");
            const game = {
              label: res.game.title,
              value: res.game.id,
            };
            const matchStartDate =
              res?.scheduleSettings?.firstRoundStartDate?.split(" ")[0];
            const matchStartTime = res?.scheduleSettings?.firstRoundStartDate
              ?.split(" ")[1]
              .split(":");
            const dateStartTime = res?.startedAt?.split(" ")[1].split(":");
            const dateFinishTime = res?.endedAt?.split(" ")[1].split(":");
            const registrationStartTime = res?.registrationStartedAt
              ?.split(" ")[1]
              .split(":");
            const registrationFinishTime = res?.registrationEndedAt
              ?.split(" ")[1]
              .split(":");

            setTournamentToEdit(res);

            setValue("tournamentName", res.name);
            setValue("tournamentDescription", res.details.description);
            setValue("organizer", res.details.organizerName);
            setValue("prizeFund", res.details.prizeFund);
            setValue("rules", res.details.rules);
            setCalendarDateStart(new Date(res.startedAt));
            setCalendarDateEnd(new Date(res.endedAt));
            setCalendarDateStartReg(new Date(res.registrationStartedAt));
            setCalendarDateEndReg(new Date(res.registrationEndedAt));
            setValue("location", res.location);
            setMaxNumber(res.participantLimit);
            setValue("facebookLink", res.details.socialNetworks?.facebook);
            setValue("instagramLink", res.details.socialNetworks?.instagram);
            setValue("discordLink", res.details.socialNetworks?.discord);
            setValue("twitterLink", res.details.socialNetworks?.twitter);
            setInputArrContacts(
              res.details.contacts?.length
                ? res.details.contacts
                : [{ name: "", title: "" }]
            );
            setInputAnyPhotos(
              res.media.anyPhotos?.length ? res.media.anyPhotos : ["1"]
            );
            setInputArrOrganizerlogo(
              res.media.organizerLogo?.length ? res.media.organizerLogo : ["1"]
            );
            setInputArrPartnerlogo(
              res.media.partnerLogo?.length ? res.media.partnerLogo : ["1"]
            );
            setInputArrSponsorlogo(
              res.media.sponsorLogo?.length ? res.media.sponsorLogo : ["1"]
            );
            setTournamentLogo(res.media.logo ? res.media.logo : "");
            setBackgroundLogo(
              res.media.pageHeaderBanner ? res.media.pageHeaderBanner : ""
            );
            const newSystem = SYSTEM.find(
              (item) => item.backName === res.scheduleSettings.systemType
            );

            if (newSystem) {
              setValue("system", newSystem);
              setSystem(newSystem);
            }

            const newLayer = layersOptions.find(
              (item) => item.name === res.layer
            );
            if (newLayer) {
              setLayer(newLayer);
              setValue("layer", newLayer);
            }
            setMaxNumber(res.participantLimit);
            setTypeEvent(
              EVENT_TYPES.find((item) => item.name === res.tournamentType)
            );
            setValue(
              "type",
              EVENT_TYPES.find((item) => item.name === res.tournamentType)
            );
            setGames(res.game ? game : []);
            setValue("games", game);
            setFormat(createFormat().find((item) => item.name === size));
            setValue(
              "format",
              createFormat().find((item) => item.name === size)
            );
            setEvents(
              eventsBack.find((item) => item.id === res.event?.id && item.id)
            );
            setCommunity(
              communitysBack.find((item) => item.id === res.community.id)
            );

            const newFormat = FORMAT.find(
              (item) => item.back === res.scheduleSettings.bestOf
            );

            if (newFormat) {
              setMatchesFormat(newFormat);
              setValue("matchesFormat", newFormat);
            }

            const platforms = res.details.platform?.map((platform) => {
              const newArr = [];
              PLATFORM.forEach((item) => {
                if (item.name === platform) {
                  newArr.push(item);
                }
              });
              return newArr;
            });

            setPlatform(platforms?.flat(1));
            setMatchStart(`${matchStartTime[0]}:${matchStartTime[1]}`);

            setMatchStartDate(new Date(matchStartDate));
            setDateStartTime(`${dateStartTime[0]}:${dateStartTime[1]}`);
            setValue(
              "dateStartTime",
              `${dateStartTime[0]}:${dateStartTime[1]}`
            );

            setDateFinishTime(`${dateFinishTime[0]}:${dateFinishTime[1]}`);
            setValue(
              "dateFinishTime",
              `${dateFinishTime[0]}:${dateFinishTime[1]}`
            );

            setRegistrationStartTime(
              `${registrationStartTime[0]}:${registrationStartTime[1]}`
            );
            setValue(
              "registrationStartTime",
              `${registrationStartTime[0]}:${registrationStartTime[1]}`
            );

            setRegistrationFinishTime(
              `${registrationFinishTime[0]}:${registrationFinishTime[1]}`
            );
            setValue(
              "registrationFinishTime",
              `${registrationFinishTime[0]}:${registrationFinishTime[1]}`
            );
            setValue("matchStartDate", matchStartDate);
            setValue("matchStart", `${matchStartTime[0]}:${matchStartTime[1]}`);
            // setMatchesInInterval(res.scheduleSettings.maxSameTimeMatchesCount);
            // setMatchInterval({
            //   id: 1,
            //   name: res.scheduleSettings.intervalMinutes,
            // });
            // setValue("matchInterval", {
            //   id: 1,
            //   name: res.scheduleSettings.intervalMinutes,
            // });
            // setValue("matchesInInterval", res.scheduleSettings.intervalMinutes);
            const countries = res.details.countries?.map((country) => {
              const newArr = [];
              countryOptions.forEach((item) => {
                if (item.id === country) {
                  newArr.push(item);
                }
              });
              return newArr;
            });
            setCountry(countries?.flat(1));
            setGender(
              GENDER.find((gender) => gender.name === res.details.gender)
            );
            setMinAge(res.details.minimumAge);
            setValue(
              "elegibilityRules",
              res.details.minimumAge ||
                res.details.gender ||
                res.details.countries?.length
                ? { id: 1, name: "yes" }
                : { id: 2, name: "no" }
            );
            setElegibilityRules(
              res.details.minimumAge ||
                res.details.gender ||
                res.details.countries?.length
                ? { id: 1, name: "yes" }
                : { id: 2, name: "no" }
            );
            setValue(
              "requiredCheckIn",
              res.checkInRequired
                ? { id: true, name: "Yes" }
                : { id: false, name: "No" }
            );
            setRequiredCheckIn(
              res.checkInRequired
                ? { id: true, name: "Yes" }
                : { id: false, name: "No" }
            );

            setValue(
              "autocompleteMatchType",
              res.autocompleteMatchType === "midnightUTC"
                ? { id: "midnightUTC", name: "23:59:59 match day" }
                : res.autocompleteMatchType === "start3hours"
                ? { id: "start3hours", name: "In 3 hours after a match start" }
                : { id: "manually", name: "Manually" }
            );
            setAutocompleteMatchType(
              res.autocompleteMatchType === "midnightUTC"
                ? { id: "midnightUTC", name: "23:59:59 match day" }
                : res.autocompleteMatchType === "start3hours"
                ? { id: "start3hours", name: "In 3 hours after a match start" }
                : { id: "manually", name: "Manually" }
            );

            setNumOfStages(
              res.scheduleSettings.ffaScheduleSettings.numberOfStages
            );
            setNumOfParticipantsInGroup(
              res.scheduleSettings.ffaScheduleSettings
                .numberOfParticipantsInGroup
            );
            setNumOfParticipantsToNextStage(
              res.scheduleSettings.ffaScheduleSettings
                .numberOfParticipantsGoingToNext
            );
            setNumOfPointsForKills(
              res.scheduleSettings.ffaScheduleSettings.numberOfPointsForKill
            );
            const scores = [];
            for (
              let i = 1;
              i <= res.scheduleSettings.ffaScheduleSettings.scoreForPlace;
              i++
            ) {
              scores.push({ name: i, value: "" });
            }
            setScoresForPlace(scores);
            setScoresForPlace(
              res.scheduleSettings.ffaScheduleSettings.scoreForPlace
            );
            setMaxNumInGroup(res.scheduleSettings.oneGroupTeamsCount);
            setValue("maxNumInGroup", res.scheduleSettings.oneGroupTeamsCount);
            setDirectSlots([
              { id: 1, name: res.scheduleSettings.membersPassageToNextStage },
            ]);
            setValue("directSlots", [
              { id: 1, name: res.scheduleSettings.membersPassageToNextStage },
            ]);
            setsecondChanceSlots([
              { id: 1, name: res.scheduleSettings.membersForTestMatches },
            ]);
            setValue("secondChanceSlots", [
              { id: 1, name: res.scheduleSettings.membersForTestMatches },
            ]);
            setSecondChance(
              res.scheduleSettings.membersForTestMatches > 0
                ? { id: 1, name: "yes" }
                : { id: 2, name: "no" }
            );
            setValue(
              "secondChance",
              res.scheduleSettings.membersForTestMatches > 0
                ? { id: 1, name: "yes" }
                : { id: 2, name: "no" }
            );
            setTiebreak(
              res.scheduleSettings.ffaScheduleSettings.tiebreakOptionsByPriority
                ?.length
                ? { id: 1, name: "yes" }
                : { id: 2, name: "no" }
            );
            setValue(
              "tiebreak",
              res.scheduleSettings.ffaScheduleSettings.tiebreakOptionsByPriority
                ?.length
                ? { id: 1, name: "yes" }
                : { id: 2, name: "no" }
            );
            setTiebreakPriority(
              res.scheduleSettings.ffaScheduleSettings.tiebreakOptionsByPriority
            );
            setCanJudge(
              res.onlyRefereesCanEnterResults
                ? { id: 1, name: "yes" }
                : { id: 2, name: "no" }
            );
            setValue(
              "canJudge",
              res.onlyRefereesCanEnterResults
                ? { id: 1, name: "yes" }
                : { id: 2, name: "no" }
            );
            setValue(
              "intervalBetweenRounds",
              res.scheduleSettings.betweenRoundsInterval
            );
            setIntervalBetweenRounds(
              res.scheduleSettings.betweenRoundsInterval
            );
            dispatch(removeSpinner());
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(removeSpinner());
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchCountries = async () => {
    try {
      const { data } = await axios.get(
        "https://newapi.passport.gg/public/labels/countries"
      );
      const options = [];
      for (let key in data) {
        options.push({ id: key, name: data[key] });
      }
      setCountryOptions(options);
    } catch (e) {
      console.error(e);
    }
  };

  //Мультиязычность Placeholder
  const tournamentNamePlaceholder = useIntl().formatMessage({
    id: "createTournament.title.tournamentName.placeholder",
  });
  const descriptionPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.description.placeholder",
  });
  const organizerPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.organizer.placeholder",
  });
  const participantsNumberPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.participantsNumber",
  });

  const elegibilityRulesPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleElegibilityRulesPlaceholder",
  });
  const minAgePlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleMinAge",
  });
  const countryPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleCountry",
  });
  const genderPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleGender",
  });
  const canJudgePlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleSubstitutePlayerPlaceholder",
  });

  const directSlotPlacesPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.directSlotsPlaces",
  });
  const systemPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleSystem.placeholder",
  });
  const secondChancePlaceholder = useIntl().formatMessage({
    id: "createTournament.title.secondChancePlaceholder",
  });
  const secondChanceSlotsPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.secondChanceSlotsPlaceholder",
  });
  const matchesFormatPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.matchesFormatPlaceholder",
  });
  const typeTournamentPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleTypeTournament.placeholder",
  });
  const formatPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.format.placeholder",
  });
  const maximumNumberPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleMaximumNumber.placeholder",
  });
  const locationPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleLocation.placeholder",
  });
  const communityPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleCommunity.placeholder",
  });
  const eventPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleEvent.placeholder",
  });
  const prizePlaceholder = useIntl().formatMessage({
    id: "createTournament.title.prize.placeholder",
  });
  const layerPlaceholder = useIntl().formatMessage({
    id: "createEvent.title.titleLayer.placeholder",
  });
  const rulesPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.rules.titleLayer",
  });
  const titleContactsPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleContacts.placeholder",
  });
  const titleContactsSecondPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleContacts.second.placeholder",
  });
  const ServerAnswerSuccess = useIntl().formatMessage({
    id: `${
      editId
        ? "createTournament.serverAnswer.success.edit"
        : "createTournament.serverAnswer.success.create"
    }`,
  });

  const ServerAnswerSuccessDisableEdit = useIntl().formatMessage({
    id: `createTournament.serverAnswer.success.edit.disable`,
  });

  const matchStartPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleStartTimePlaceholder",
  });
  const matchIntervalPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleMatchIntervalPlaceholder",
  });
  const matchesInIntervalPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleMatchesInIntervalPlaceholder",
  });

  const maxNumInGroupPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.maxParticipantsInGroupsPlaceholder",
  });

  const substitutePlayerPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleSubstitutePlayerPlaceholder",
  });

  const numOfStagesPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.titleNumOfStagesPlaceholder",
  });

  const numOfPointsForKillsPlaceholder = useIntl().formatMessage({
    id: "createTournament.title.numOfPointsForKillsPlaceholder",
  });

  //dateStart
  const [calendarDateStart, setCalendarDateStart] = useState(new Date());
  const [calendarDateStartReg, setCalendarDateStartReg] = useState(new Date());

  //dateEnd
  const [calendarDateEnd, setCalendarDateEnd] = useState(new Date());
  const [calendarDateEndReg, setCalendarDateEndReg] = useState(new Date());

  /* const [userId, setUserId] = useState(''); Удалить?*/
  const [layer, setLayer] = useState(null);

  const [gamesBack, setGamesFromBack] = useState("");
  const [games, setGames] = useState("");
  const [typeEvent, setTypeEvent] = useState("");
  const [maxNumber, setMaxNumber] = useState(2);

  //Events
  const [events, setEvents] = useState(null);

  //SOCIAL NETWORKS
  //facebook
  const [facebookLink, setFacebookLink] = useState("");
  //instagram
  const [instagramLink, setInstagramLink] = useState("");
  //discord
  const [discordLink, setDiscordLink] = useState("");
  //twitter
  const [twitterLink, setTwitterLink] = useState("");

  //new fields
  const [system, setSystem] = useState("");
  const [directSlots, setDirectSlots] = useState("");
  const [secondChance, setSecondChance] = useState({ id: 2, name: "no" });
  const [secondChanceSlots, setsecondChanceSlots] = useState("");
  const [matchesFormat, setMatchesFormat] = useState("");
  const [elegibilityRules, setElegibilityRules] = useState("");
  const [requiredCheckIn, setRequiredCheckIn] = useState(false);
  const [autocompleteMatchType, setAutocompleteMatchType] = useState("");

  const [minAge, setMinAge] = useState("");
  const [gender, setGender] = useState({ id: 3, name: "Other" });
  const [country, setCountry] = useState("");
  const [canJudge, setCanJudge] = useState("");
  const [dateStartTime, setDateStartTime] = useState("");
  const [dateFinishTime, setDateFinishTime] = useState("");
  const [registrationStartTime, setRegistrationStartTime] = useState("");
  const [registrationFinishTime, setRegistrationFinishTime] = useState("");

  const [matchStart, setMatchStart] = useState("");
  const [matchStartDate, setMatchStartDate] = useState("");
  // const [matchInterval, setMatchInterval] = useState("");
  // const [matchesInInterval, setMatchesInInterval] = useState("");
  const [maxNumInGroup, setMaxNumInGroup] = useState(null);
  const [substitutePlayer, setSubstitutePlayer] = useState({
    id: 2,
    name: "no",
  });
  const [numOfStages, setNumOfStages] = useState(null);
  const [numOfParticipantsInGroup, setNumOfParticipantsInGroup] =
    useState(null);
  const [numOfParticipantsToNextStage, setNumOfParticipantsToNextStage] =
    useState(null);
  const [platform, setPlatform] = useState([]);
  const [numOfMapsForStage, setNumOfMapsForStage] = useState([]);
  const [scoresForPlace, setScoresForPlace] = useState([]);
  const [numOfPointsForKills, setNumOfPointsForKills] = useState(null);
  const [tiebreak, setTiebreak] = useState({ id: 2, name: "no" });
  const [tiebreakPriority, setTiebreakPriority] = useState([]);
  const [intervalBetweenRounds, setIntervalBetweenRounds] = useState();

  const [isLoading, setLoading] = useState(false);

  //Всё, что связанно с валидацией
  const [submitErr, setSubmitErr] = useState(false);
  const [contactNameErr, setContactNameErr] = useState(false);
  const [contactValueErr, setContactValueErr] = useState(false);

  //images
  const [backgroundLogo, setBackgroundLogo] = useState("");

  //add inputs Contacts
  const [inputArrContacts, setInputArrContacts] = useState([
    { name: "", title: "" },
  ]);
  //format
  const [format, setFormat] = useState("");

  //add photo
  const [tournamentLogo, setTournamentLogo] = useState("");
  const [photoArrOrganizerlogo, setInputArrOrganizerlogo] = useState(["1"]);
  const [photoArrSponsorlogo, setInputArrSponsorlogo] = useState(["1"]);
  const [photoArrPartnerlogo, setInputArrPartnerlogo] = useState(["1"]);
  const [photoAnyPhotos, setInputAnyPhotos] = useState(["1"]);

  const createRequestBody = (communityId) => {
    const scoresForPlaceBack = scoresForPlace?.map((item) => item?.value);
    const contacts = inputArrContacts[0].name ? inputArrContacts : [];
    const participationType = format.backName === 1 ? "user" : "team";

    return {
      type: system?.backName,
      checkInRequired: requiredCheckIn?.id,
      onlyRefereesCanEnterResults: canJudge?.name === "yes",
      name: watch("tournamentName"),
      organizerName: watch("organizer"),
      description: watch("tournamentDescription"),
      participationType: participationType,
      tournamentType: typeEvent?.name,
      participationLimit: maxNumber,
      participantTeamSize: format?.backName,
      autocompleteMatchType: autocompleteMatchType?.id,
      contacts: contacts,
      countries: country?.length ? country.map((item) => item.id) : [],
      platform: platform?.flat(1).map((item) => item.name),
      gender: gender?.name,
      minimumAge: +minAge,
      socialNetworks: {
        facebook: watch("facebookLink"),
        instagram: watch("instagramLink"),
        discord: watch("discordLink"),
        twitter: watch("twitterLink"),
      },
      location: watch("location"),
      layer: layer?.name,
      prizeFund: watch("prizeFund"),
      rules: watch("rules"),
      gameId: games?.value,
      eventId: events?.id,
      communityId: communityId || community?.id || communityIdValue,
      dateStart: (
        convertDateToString(calendarDateStart) +
        " " +
        dateStartTime +
        ":00"
      ).replaceAll("/", "."),
      dateFinish: (
        convertDateToString(calendarDateEnd) +
        " " +
        dateFinishTime +
        ":00"
      )
        // "  00:00:00"
        .replaceAll("/", "."),
      dateStartRegistration: (
        convertDateToString(calendarDateStartReg) +
        " " +
        registrationStartTime +
        ":00"
      ).replaceAll("/", "."),
      dateFinishRegistration: (
        convertDateToString(calendarDateEndReg) +
        " " +
        registrationFinishTime +
        ":00"
      ).replaceAll("/", "."),
      scheduleSettings: {
        firstRoundStartDate:
          moment(matchStartDate).format("YYYY-MM-DD") +
          " " +
          matchStart +
          ":00",
        // maxSameTimeMatchesCount: matchesInInterval,
        // intervalMinutes: matchInterval?.name,
        betweenRoundsInterval: +intervalBetweenRounds,
        oneGroupTeamsCount: maxNumInGroup,
        membersPassageToNextStage: directSlots?.name,
        membersForTestMatches: secondChanceSlots?.name,
        bestOf: matchesFormat?.back,
        ffaScheduleSettings: {
          numberOfStages: numOfStages,
          numberOfParticipantsInGroup: numOfParticipantsInGroup,
          numberOfParticipantsGoingToNext: numOfParticipantsToNextStage,
          numberOfStageMaps: numOfMapsForStage,
          scoreForPlace: scoresForPlaceBack,
          numberOfPointsForKill: numOfPointsForKills,
          substitutePlayer: substitutePlayer?.name === "yes",
          tiebreakOptionsByPriority: tiebreakPriority,
        },
      },
    };
  };
  console.log(createRequestBody());
  const createTournament = (res) => {
    API.request(
      !editId
        ? {
            ...API_ROUTER.Tournaments.createTournament,
            data: createRequestBody(res?.id),
          }
        : {
            ...API_ROUTER.Tournaments.updateTournament,
            pathKeys: {
              tournamentId: editId,
            },
            data: createRequestBody(res?.id),
          }
    )
      .then((res) => {
        uploadPhotos(
          tournamentToEdit,
          editId,
          res,
          uploadData,
          backgroundLogo,
          tournamentLogo,
          photoArrSponsorlogo,
          photoArrPartnerlogo,
          photoArrOrganizerlogo,
          photoAnyPhotos
        );

        setTimeout(() => {
          dispatch(showNotificationModal(ServerAnswerSuccess));
          history.push(ROUTER.tournamentsPage);
          dispatch(removeSpinner());
        }, 6000);
      })
      .catch((err) => {
        dispatch(removeSpinner());
        const errors = [];
        for (let key in err.data.errors) {
          errors.push(`${key}: ${err.data.errors[key]}`);
        }
        dispatch(
          showNotificationModal(`Some errors occured: ${errors.join(", ")}`)
        );
      });
  };

  const onSubmit = () => {
    console.log("onSubmit");
    if (!communitysBack?.length) {
      dispatch(addSpinner());
      API.request({
        ...API_ROUTER.community.createCommunity,
        data: {
          name: `Community of ${userData?.nickname}`,
          startedAt: new Date().toLocaleDateString() + "  00:00:00",
          admissionProcess: "automatic",
          contacts: ["contact goes here", "contact goes here"],
          description: "Add your text here",
          nameOfOrganizer: "Add your text here",
          rules: "Add your text here",
        },
      })
        .then((res) => {
          createTournament(res);
        })
        .catch((err) => {
          dispatch(removeSpinner());
          const errors = [];
          for (let key in err.data.errors) {
            errors.push(`${key}: ${err.data.errors[key]}`);
          }
          dispatch(
            showNotificationModal(`Some errors occured: ${errors.join(", ")}`)
          );
        });
    } else {
      dispatch(addSpinner());
      const requestParams = !editId
        ? {
            ...API_ROUTER.Tournaments.createTournament,
            data: createRequestBody(community?.id),
          }
        : {
            ...API_ROUTER.Tournaments.updateTournament,
            pathKeys: {
              tournamentId: editId,
            },
            data: createRequestBody(community?.id || communityIdValue),
          };
      API.request(requestParams)
        .then((res) => {
          uploadPhotos(
            tournamentToEdit,
            editId,
            res,
            uploadData,
            backgroundLogo,
            tournamentLogo,
            photoArrSponsorlogo,
            photoArrPartnerlogo,
            photoArrOrganizerlogo,
            photoAnyPhotos
          );

          setTimeout(() => {
            dispatch(showNotificationModal(ServerAnswerSuccess));
            history.push(ROUTER.tournamentsPage);
            dispatch(removeSpinner());
          }, 6000);
        })
        .catch((err) => {
          dispatch(removeSpinner());
          const errors = [];
          for (let key in err.data.errors) {
            errors.push(`${key}: ${err.data.errors[key]}`);
          }
          dispatch(
            showNotificationModal(`Some errors occured: ${errors.join(", ")}`)
          );
        });
    }
  };

  useEffect(() => {
    createStages(numOfStages, setNumOfMapsForStage);
  }, [numOfStages]);

  useEffect(() => {
    getCommunityEvents();
  }, [community?.id]);

  const getFormData = async () => {
    setLoading(true);
    await getUserRights();
    await getCommunities();
    await getCurrentGames();
    await fetchCountries();
    await setLoading(false);
  };

  useEffect(() => {
    if (layersOptions.length) {
      if (editId) {
        getTournamentById();
      }
    }
  }, [layersOptions.length]);

  useEffect(() => {
    getFormData();
  }, []);

  useEffect(() => {
    createScoresForPlaces(numOfParticipantsInGroup, setScoresForPlace);
  }, [numOfParticipantsInGroup]);

  if (isLoading) {
    return <div className={s.loading}>Loading...</div>;
  }

  return (
    <Container>
      <div className={s.mainBlock}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={s.contentHeaderTitleContainer}>
            <div className={s.eventsHeaderTitleContainer}>
              <div className={s.title}>
                {!editId ? (
                  <FormattedMessage
                    id="createTournament.pageName.title"
                    tagName="span"
                  />
                ) : (
                  <FormattedMessage
                    id="editTournament.pageName.title"
                    tagName="span"
                  />
                )}
              </div>
            </div>
            <div className={s.tabsWrapper}>
              <div style={{ width: "100%", marginRight: "15px" }}>
                <div
                  className={s.tab}
                  style={{
                    background: tabsForm === "description" && "#B67E2A",
                  }}
                  onClick={() => tabHandler("description")}
                >
                  Description
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  className={s.tab}
                  style={{
                    background: tabsForm === "settings" && "#B67E2A",
                  }}
                  onClick={() => tabHandler("settings")}
                >
                  Settings
                </div>
              </div>
            </div>
            {/* {tabsForm === "description" && ( */}
            <div
              className={
                tabsForm === "description"
                  ? s.showDesctiptionBlockActive
                  : s.showDesctiptionBlockHidden
              }
            >
              <div className={s.mainSattingsContainer}>
                <div className={s.settingsContainer}>
                  <div className={s.inputContainer}>
                    <div className={s.titleInput}>
                      <FormattedMessage
                        id="createTournament.title.tournamentName"
                        tagName="span"
                      />
                    </div>
                    {errors.tournamentName?.type === "required" && (
                      <span className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </span>
                    )}
                    {errors.tournamentName?.type === "minLength" && (
                      <span className={s.messageErr}>
                        <FormattedMessage
                          id="createLanding.err.errMessageLong"
                          tagName="span"
                        />
                      </span>
                    )}
                    <input
                      disabled={
                        calendarDateStartReg < currentDate() && !!editId
                      }
                      className={classNames({
                        [s.inputErr]: errors.tournamentName,
                      })}
                      {...register("tournamentName", {
                        required: true,
                        minLength: 2,
                      })}
                      placeholder={tournamentNamePlaceholder}
                      type="text"
                    />
                  </div>
                  <div className={s.imageContainer}>
                    <div
                      className={s.uploadTitle}
                      style={{
                        marginBottom: 15,
                        background: isHOGDomain() && "rgb(132, 100, 20)",
                      }}
                    >
                      <FormattedMessage
                        id="createTournament.title.BGImg"
                        tagName="span"
                      />
                    </div>
                    <FileUploaderSingle
                      disabled={
                        calendarDateStartReg < currentDate() && !!editId
                      }
                      defaultFoto={props.uploadImg}
                      setImageURL={setBackgroundLogo}
                      imageURL={backgroundLogo}
                      htmlFor={"backgroundLogo"}
                    />
                  </div>
                </div>
              </div>
              <div className={s.descriptionContainer} style={{ marginTop: 50 }}>
                <div className={s.descriptionTitle}>
                  <FormattedMessage
                    id="createTournament.title.description"
                    tagName="span"
                  />
                </div>
                {errors.tournamentDescription?.type === "required" && (
                  <span className={s.messageErr}>
                    <FormattedMessage
                      id="createTournament.title.titleObligatory"
                      tagName="span"
                    />
                  </span>
                )}
                {errors.tournamentDescription?.type === "minLength" && (
                  <span className={s.messageErr}>
                    <FormattedMessage
                      id="createLanding.err.errMessageLong"
                      tagName="span"
                    />
                  </span>
                )}
                <div
                  className={classNames({
                    [s.textareaErr]: errors.tournamentDescription,
                  })}
                >
                  <textarea
                    {...register("tournamentDescription", {
                      required: true,
                      minLength: 2,
                    })}
                    className={s.descriptionTextarea}
                    placeholder={descriptionPlaceholder}
                    type="text"
                    style={{ background: isHOGDomain() && "#000" }}
                    disabled={calendarDateStartReg < currentDate() && !!editId}
                  ></textarea>
                </div>
              </div>
              {/* organizer */}
              <div className={s.organizerPartnerFromContainr}>
                <div className={s.inputMainBottomContainer}>
                  <div className={s.inputContainer}>
                    <div className={s.title} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.organizer"
                        tagName="span"
                      />
                    </div>
                    {errors.organizer?.type === "required" && (
                      <span className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </span>
                    )}
                    {errors.organizer?.type === "minLength" && (
                      <span className={s.messageErr}>
                        <FormattedMessage
                          id="createLanding.err.errMessageLong"
                          tagName="span"
                        />
                      </span>
                    )}
                    <div
                      className={classNames({
                        [s.textareaErr]: errors.organizer,
                      })}
                    >
                      <input
                        {...register("organizer", {
                          required: true,
                          minLength: 2,
                        })}
                        className={s.descriptionTextarea}
                        placeholder={organizerPlaceholder}
                        type="text"
                        disabled={
                          calendarDateStartReg < currentDate() &&
                          calendarDateStart > currentDate() &&
                          !!editId
                        }
                      />
                    </div>
                  </div>

                  <div className={s.imageContainer}>
                    <div className={s.imageContainer}>
                      <div className={s.imgTitle} style={{ marginBottom: 20 }}>
                        <span
                          style={{
                            background: isHOGDomain() && "rgb(132, 100, 20)",
                          }}
                        >
                          <FormattedMessage id="createTournament.title.imgTitle.tournamentLogo" />
                        </span>
                      </div>
                      <FileUploaderSingle
                        defaultFoto={props.uploadImg}
                        setImageURL={setTournamentLogo}
                        imageURL={tournamentLogo}
                        htmlFor={"tournamentLogo"}
                        disabled={
                          calendarDateStartReg < currentDate() && !!editId
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className={s.inputMainBottomContainer}>
                  <div className={s.imageContainer}>
                    <div className={s.imageContainer}>
                      <div className={s.imgTitle}>
                        <span
                          style={{
                            background: isHOGDomain() && "rgb(132, 100, 20)",
                          }}
                        >
                          <FormattedMessage id="createTournament.title.imgTitle.sponsorLogo" />
                        </span>
                      </div>
                      {photoArrSponsorlogo.map((el, i) => (
                        <div>
                          <div
                            key={`photoArrSponsorlogo-${i}`}
                            className={s.btnContainer}
                          >
                            <div
                              className={s.delBtn}
                              onClick={() =>
                                delPhoto(
                                  i,
                                  setInputArrSponsorlogo,
                                  photoArrSponsorlogo,
                                  editId
                                )
                              }
                            ></div>
                            <FileUploaderSingle
                              defaultFoto={props.uploadImg}
                              imageURL={photoArrSponsorlogo[i]}
                              htmlFor={`sponsor${i}`}
                              isMany={true}
                              onChangeDudlePhotos={onChangeDudlePhotos}
                              id={i}
                              fn={setInputArrSponsorlogo}
                              data={photoArrSponsorlogo}
                              disabled={
                                calendarDateStart > currentDate() &&
                                calendarDateEnd < currentDate() &&
                                !!editId
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className={s.addBtnContainer}>
                      <button
                        disabled={
                          calendarDateStart > currentDate() &&
                          calendarDateEnd < currentDate() &&
                          !!editId
                        }
                        type="button"
                        style={{
                          background: isHOGDomain() && "rgb(132, 100, 20)",
                        }}
                        className={s.addBtn}
                        onClick={() =>
                          addPhotos(photoArrSponsorlogo, setInputArrSponsorlogo)
                        }
                      >
                        <FormattedMessage
                          id="createCommunity.btn.addSponsorLogo"
                          tagName="span"
                        />
                      </button>
                    </div>
                  </div>

                  <div className={s.imageContainer}>
                    <div className={s.imgTitle}>
                      <span
                        style={{
                          background: isHOGDomain() && "rgb(132, 100, 20)",
                        }}
                      >
                        <FormattedMessage id="createTournament.title.imgTitle.partnerLogo" />
                      </span>
                    </div>
                    {photoArrPartnerlogo.map((el, i) => (
                      <div>
                        <div
                          key={`photoArrPartnerlogo-${i}`}
                          className={s.btnContainer}
                        >
                          <div
                            className={s.delBtn}
                            onClick={() =>
                              delPhoto(
                                i,
                                setInputArrPartnerlogo,
                                photoArrPartnerlogo,
                                editId
                              )
                            }
                          ></div>
                          <FileUploaderSingle
                            disabled={
                              calendarDateStart > currentDate() &&
                              calendarDateEnd < currentDate() &&
                              !!editId
                            }
                            defaultFoto={props.uploadImg}
                            imageURL={photoArrPartnerlogo[i]}
                            htmlFor={`partner${i}`}
                            isMany
                            onChangeDudlePhotos={onChangeDudlePhotos}
                            id={i}
                            fn={setInputArrPartnerlogo}
                            data={photoArrPartnerlogo}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className={s.addBtnContainer}>
                    <button
                      disabled={
                        calendarDateStart > currentDate() &&
                        calendarDateEnd < currentDate() &&
                        !!editId
                      }
                      type="button"
                      className={s.addBtn}
                      style={{
                        background: isHOGDomain() && "rgb(132, 100, 20)",
                      }}
                      onClick={() =>
                        addPhotos(photoArrPartnerlogo, setInputArrPartnerlogo)
                      }
                    >
                      <FormattedMessage
                        id="createTournament.btn.addPartnerLogo"
                        tagName="span"
                      />
                    </button>
                  </div>

                  <div className={s.imageContainer}>
                    <div className={s.imageContainer}>
                      <div className={s.imgTitle}>
                        <span>
                          <FormattedMessage id="createTournament.title.imgTitle.organizerLogo" />
                        </span>
                      </div>
                      {photoArrOrganizerlogo.map((el, i) => (
                        <div>
                          <div
                            key={`photoArrOrganizerlogo-${i}`}
                            className={s.btnContainer}
                          >
                            <div
                              className={s.delBtn}
                              onClick={() =>
                                delPhoto(
                                  i,
                                  setInputArrOrganizerlogo,
                                  photoArrOrganizerlogo,
                                  editId
                                )
                              }
                            ></div>
                            <FileUploaderSingle
                              defaultFoto={props.uploadImg}
                              imageURL={photoArrOrganizerlogo[i]}
                              htmlFor={`organizerLogo${i}`}
                              isMany
                              onChangeDudlePhotos={onChangeDudlePhotos}
                              id={i}
                              fn={setInputArrOrganizerlogo}
                              data={photoArrOrganizerlogo}
                              disabled={
                                calendarDateStartReg < currentDate() &&
                                calendarDateStart < currentDate() &&
                                !!editId
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className={s.addBtnContainer}>
                      <button
                        disabled={
                          calendarDateStartReg < currentDate() &&
                          calendarDateStart < currentDate() &&
                          !!editId
                        }
                        type="button"
                        className={s.addBtn}
                        style={{
                          background: isHOGDomain() && "rgb(132, 100, 20)",
                        }}
                        onClick={() =>
                          addPhotos(
                            photoArrOrganizerlogo,
                            setInputArrOrganizerlogo
                          )
                        }
                      >
                        <FormattedMessage
                          id="createTournament.btn.addOrganizerLogo"
                          tagName="span"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={s.inputMainBottomContainer}
                style={{ marginTop: 56 }}
              >
                <div className={s.imageContainer}>
                  <div className={s.imgTitle}>
                    <span
                      style={{
                        background: isHOGDomain() && "rgb(132, 100, 20)",
                      }}
                    >
                      <FormattedMessage id="createTournament.title.titlePhotos" />
                    </span>
                  </div>
                  {photoAnyPhotos.map((el, i) => (
                    <div>
                      <div
                        key={`photoAnyPhotos-${i}`}
                        className={s.btnContainer}
                      >
                        <div
                          className={s.delBtn}
                          onClick={() =>
                            delPhoto(
                              i,
                              setInputAnyPhotos,
                              photoAnyPhotos,
                              editId
                            )
                          }
                          style={{ top: 5 }}
                        ></div>
                        <FileUploaderSingle
                          style={{ marginTop: 0 }}
                          defaultFoto={props.uploadImg}
                          imageURL={photoAnyPhotos[i]}
                          htmlFor={`anyPhotos${i}`}
                          isMany={true}
                          onChangeDudlePhotos={onChangeDudlePhotos}
                          id={i}
                          fn={setInputAnyPhotos}
                          data={photoAnyPhotos}
                          disabled={
                            calendarDateStartReg < currentDate() && !!editId
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className={s.addBtnContainer}>
                  <button
                    disabled={calendarDateStartReg < currentDate() && !!editId}
                    type="button"
                    className={s.addBtn}
                    style={{
                      background: isHOGDomain() && "rgb(132, 100, 20)",
                    }}
                    onClick={() => addPhotos(photoAnyPhotos, setInputAnyPhotos)}
                  >
                    <FormattedMessage
                      id="createCommunity.btn.addAnyPhotosLogo"
                      tagName="span"
                    />
                  </button>
                </div>
              </div>

              {/* PLATFORM */}
              <div className={s.selectContainer}>
                <div className={s.text} style={{ marginBottom: 10 }}>
                  <FormattedMessage
                    id="createTournament.title.titlePlatform"
                    tagName="span"
                  />
                </div>
                {!platform?.length && (
                  <p className={s.messageErr}>
                    <FormattedMessage
                      id="createTournament.title.titleObligatory"
                      tagName="span"
                    />
                  </p>
                )}
                <SelectWithCheckboxes
                  value={platform}
                  disabled={calendarDateStartReg < currentDate() && !!editId}
                  options={PLATFORM}
                  placeholder="Select platform"
                  setState={setPlatform}
                />
              </div>

              {/* Type of the Event*/}
              <div className={s.selectContainer}>
                <div className={s.text} style={{ marginBottom: 10 }}>
                  <FormattedMessage
                    id="createTournament.title.titleTypeTournament"
                    tagName="span"
                  />
                </div>
                {errors.type?.type === "required" && (
                  <p className={s.messageErr}>
                    <FormattedMessage
                      id="createTournament.title.titleObligatory"
                      tagName="span"
                    />
                  </p>
                )}
                <div
                  className={s.select}
                  style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
                >
                  <Controller
                    name="type"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={typeEvent}
                    render={({ field }) => (
                      <Select
                        onChange={(e) => {
                          field.onChange(e);
                          setTypeEvent(e);
                        }}
                        value={typeEvent}
                        classNamePrefix="custom_select"
                        options={EVENT_TYPES}
                        placeholder={typeTournamentPlaceholder}
                        isMulti={false}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        disabled={
                          calendarDateStartReg < currentDate() && !!editId
                        }
                      />
                    )}
                  />
                </div>
              </div>

              {!!(
                typeEvent.name === "offline" || typeEvent.name === "mixed"
              ) && (
                <div className={s.inputContainer}>
                  <div className={s.titleInput}>
                    <FormattedMessage
                      id="createTournament.title.titleLocation"
                      tagName="span"
                    />
                  </div>
                  {errors.location?.type === "required" && (
                    <span className={s.messageErr}>
                      <FormattedMessage
                        id="createTournament.title.titleObligatory"
                        tagName="span"
                      />
                    </span>
                  )}
                  {errors.location?.type === "minLength" && (
                    <span className={s.messageErr}>
                      <FormattedMessage
                        id="createLanding.err.errMessageLong"
                        tagName="span"
                      />
                    </span>
                  )}
                  <input
                    {...register("location", {
                      required: true,
                      minLength: 2,
                    })}
                    className={classNames({ [s.inputErr]: errors.location })}
                    type="text"
                    placeholder={locationPlaceholder}
                  />
                </div>
              )}
              {/* CONTACTS */}
              <div className={s.inputContainerLower}>
                <div className={s.inputText}>
                  <FormattedMessage
                    id="createTournament.title.titleContacts"
                    tagName="span"
                  />
                </div>
                {inputArrContacts.map((el, i) => (
                  <div
                    className={`${s.inputBlock} ${s.inputBr}`}
                    key={`inputArrContacts-${i}`}
                  >
                    <div className={s.inputContainer} style={{ marginTop: 0 }}>
                      {contactNameErr && (
                        <p
                          className={s.messageErr}
                          style={{
                            top: "-17px",
                            left: 0,
                          }}
                        >
                          <FormattedMessage
                            id="createLanding.err.errMessageLong"
                            tagName="span"
                          />
                        </p>
                      )}
                      <input
                        type="text"
                        placeholder={titleContactsPlaceholder}
                        className={classNames({
                          [s.inputErr]: contactNameErr,
                        })}
                        value={inputArrContacts[i].name}
                        onChange={(e) =>
                          onChangeDudleInput(
                            e,
                            i,
                            "name",
                            setInputArrContacts,
                            inputArrContacts,
                            setContactNameErr
                          )
                        }
                        // onBlur={chakedForm}
                        disabled={
                          calendarDateStart > currentDate() &&
                          calendarDateEnd < currentDate() &&
                          !!editId
                        }
                      />
                    </div>
                    <div className={s.inputContainer} style={{ marginTop: 0 }}>
                      {contactValueErr && (
                        <p
                          className={s.messageErr}
                          style={{
                            top: "-17px",
                            left: 0,
                          }}
                        >
                          <FormattedMessage
                            id="createLanding.err.errMessageLong"
                            tagName="span"
                          />
                        </p>
                      )}
                      <input
                        disabled={
                          calendarDateStart > currentDate() &&
                          calendarDateEnd < currentDate() &&
                          !!editId
                        }
                        type="text"
                        placeholder={titleContactsSecondPlaceholder}
                        className={classNames({
                          [s.inputErr]: contactValueErr,
                        })}
                        value={inputArrContacts[i].title}
                        onChange={(e) =>
                          onChangeDudleInput(
                            e,
                            i,
                            "title",
                            setInputArrContacts,
                            inputArrContacts,
                            setContactValueErr
                          )
                        }
                      />
                    </div>
                    <button
                      onClick={() =>
                        handleDelete(i, inputArrContacts, setInputArrContacts)
                      }
                      style={{
                        width: 50,
                        cursor: "pointer",
                        marginBottom: 5,
                        background: isHOGDomain() && "rgb(132, 100, 20)",
                      }}
                      type="button"
                    >
                      <DeleteOutlinedIcon />
                    </button>
                  </div>
                ))}

                <div className={s.addBtnContainer}>
                  <div
                    disabled={
                      calendarDateStart > currentDate() &&
                      calendarDateEnd < currentDate() &&
                      !!editId
                    }
                    className={s.addImg}
                    style={{
                      background: isHOGDomain() && "rgb(132, 100, 20)",
                    }}
                    onClick={() =>
                      addInputs(inputArrContacts, setInputArrContacts)
                    }
                  ></div>
                </div>
              </div>
              {/* SOCIAL */}
              <div className={s.socialNetworksContainer}>
                <div className={s.inputText}>
                  <FormattedMessage
                    id="createTournament.title.titleSocialNetworks"
                    tagName="span"
                  />
                </div>
                <div>
                  <div className={s.title}>
                    <FormattedMessage
                      id="createTournament.title.titleFacebook"
                      tagName="span"
                    />
                  </div>
                  {errors.facebookLink?.type === "pattern" && (
                    <p className={s.messageErr}>
                      <FormattedMessage
                        id="err.errMessage.СheckFBData"
                        tagName="span"
                      />
                    </p>
                  )}
                  <div className={s.inputContainer} style={{ marginTop: 0 }}>
                    <input
                      type="text"
                      placeholder="Link"
                      className={classNames({
                        [s.inputErr]: errors.facebookLink,
                      })}
                      {...register("facebookLink", { pattern: /facebook/i })}
                      onChange={(e) => setFacebookLink(e.target.value)}
                      disabled={
                        calendarDateStart > currentDate() &&
                        calendarDateEnd < currentDate() &&
                        !!editId
                      }
                    />
                  </div>
                </div>
                <div className={s.title}>
                  <FormattedMessage
                    id="createTournament.title.titleInstagram"
                    tagName="span"
                  />
                </div>
                {errors.instagramLink?.type === "pattern" && (
                  <p className={s.messageErr}>
                    <FormattedMessage
                      id="err.errMessage.СheckInstData"
                      tagName="span"
                    />
                  </p>
                )}
                <div className={s.inputContainer} style={{ marginTop: 0 }}>
                  <input
                    type="text"
                    {...register("instagramLink", { pattern: /instagram/i })}
                    placeholder="Link"
                    className={classNames({
                      [s.inputErr]: errors.instagramLink,
                    })}
                    onChange={(e) => setInstagramLink(e.target.value)}
                    disabled={
                      calendarDateStart > currentDate() &&
                      calendarDateEnd < currentDate() &&
                      !!editId
                    }
                  />
                </div>
                <div className={s.title}>
                  <FormattedMessage
                    id="createTournament.title.titleDiscord"
                    tagName="span"
                  />
                </div>
                {errors.discordLink?.type === "pattern" && (
                  <p className={s.messageErr}>
                    <FormattedMessage
                      id="err.errMessage.СheckDiscordData"
                      tagName="span"
                    />
                  </p>
                )}
                <div className={s.inputContainer} style={{ marginTop: 0 }}>
                  <input
                    type="text"
                    placeholder="Link"
                    {...register("discordLink", { pattern: /discord/i })}
                    className={classNames({
                      [s.inputErr]: errors.discordLink,
                    })}
                    onChange={(e) => setDiscordLink(e.target.value)}
                    disabled={
                      calendarDateStart > currentDate() &&
                      calendarDateEnd < currentDate() &&
                      !!editId
                    }
                  />
                </div>
                <div className={s.title}>
                  <FormattedMessage
                    id="createTournament.title.titleTwitter"
                    tagName="span"
                  />
                </div>
                {errors.twitterLink?.type === "pattern" && (
                  <p className={s.messageErr}>
                    <FormattedMessage
                      id="err.errMessage.СheckTwitterData"
                      tagName="span"
                    />
                  </p>
                )}
                <div className={s.inputContainer} style={{ marginTop: 0 }}>
                  <input
                    {...register("twitterLink", { pattern: /twitter/i })}
                    type="text"
                    placeholder="Link"
                    className={classNames({
                      [s.inputErr]: errors.twitterLink,
                    })}
                    onChange={(e) => setTwitterLink(e.target.value)}
                    disabled={
                      calendarDateStart > currentDate() &&
                      calendarDateEnd < currentDate() &&
                      !!editId
                    }
                  />
                </div>
              </div>

              {/* Community*/}
              <div className={s.selectContainer}>
                <div className={s.text} style={{ marginBottom: 10 }}>
                  <FormattedMessage
                    id="createTournament.title.titleCommunity"
                    tagName="span"
                  />
                </div>
                {!editId && (
                  <p style={{ marginBottom: 10 }}>
                    <FormattedMessage
                      id="createTournament.text.communityTip"
                      tagName="span"
                    />
                  </p>
                )}
                {editId && (
                  <p style={{ marginBottom: 10 }}>
                    <FormattedMessage
                      id="createTournament.text.communityEdit1"
                      tagName="span"
                    />{" "}
                    <a
                      style={{ color: "white", textDecoration: "underline" }}
                      href="https://about.passport.gg/adminsupport"
                    >
                      <FormattedMessage
                        id="createTournament.text.communityEdit1"
                        tagName="span"
                      />
                    </a>
                  </p>
                )}
                <div
                  className={s.select}
                  style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
                >
                  {!editId ? (
                    <Select
                      value={community}
                      classNamePrefix="custom_select"
                      options={communitysBack?.length && communitysBack}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      name="community"
                      isMulti={false}
                      placeholder={communityPlaceholder}
                      onChange={(e) => setCommunity(e)}
                      // onBlur={(e) => chakedSelct(e.length > 0)}
                    />
                  ) : (
                    <Select
                      value={community}
                      // onChange={(e) => setCommunity(e)}
                      classNamePrefix="custom_select"
                      options={communitysBack}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      name="community"
                      isMulti={false}
                      placeholder={communityPlaceholder}
                      disabled={
                        calendarDateStartReg < currentDate() && !!editId
                      }
                    />
                  )}
                </div>
              </div>

              {/* Event*/}
              <div className={s.selectContainer}>
                <div className={s.text} style={{ marginBottom: 10 }}>
                  <FormattedMessage
                    id="createTournament.title.titleEvent"
                    tagName="span"
                  />
                </div>
                {editId && (
                  <p style={{ marginBottom: 10 }}>
                    <FormattedMessage
                      id="createTournament.text.eventEdit1"
                      tagName="span"
                    />{" "}
                    <a
                      style={{ color: "white", textDecoration: "underline" }}
                      href="https://about.passport.gg/adminsupport"
                    >
                      <FormattedMessage
                        id="createTournament.text.communityEdit1"
                        tagName="span"
                      />
                    </a>
                  </p>
                )}
                <div
                  className={s.select}
                  style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
                >
                  {!editId ? (
                    <Select
                      value={events}
                      classNamePrefix="custom_select"
                      options={eventsBack}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      name="type"
                      isMulti={false}
                      placeholder={eventPlaceholder}
                      onChange={(e) => setEvents(e)}
                    />
                  ) : (
                    <Select
                      value={events}
                      classNamePrefix="custom_select"
                      options={eventsBack}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      name="type"
                      isMulti={false}
                      onChange={(e) => setEvents(e)}
                      placeholder={eventPlaceholder}
                      disabled={
                        calendarDateStartReg < currentDate() && !!editId
                      }
                    />
                  )}
                </div>
              </div>

              <div className={s.btnContainer} style={{ marginTop: 46 }}>
                <div className={s.text} style={{ marginBottom: 10 }}>
                  <FormattedMessage
                    id="createTournament.title.titleCircuit"
                    tagName="span"
                  />
                </div>
                <a
                  target="_blank"
                  href="https://about.passport.gg/officialstatusrequest"
                  rel="noreferrer"
                >
                  <button
                    disabled={calendarDateStartReg < currentDate() && !!editId}
                    type="button"
                    style={{
                      background: isHOGDomain() && "rgb(132, 100, 20)",
                    }}
                  >
                    <FormattedMessage
                      id="createTournament.btnText.affilation"
                      tagName="span"
                    />
                  </button>
                </a>
              </div>

              <div className={s.btnContainer} style={{ marginTop: 46 }}>
                <div className={s.text} style={{ marginBottom: 10 }}>
                  <FormattedMessage
                    id="createTournament.title.titleRating"
                    tagName="span"
                  />
                </div>
                <a
                  target="_blank"
                  href="https://about.passport.gg/officialstatusrequest"
                  rel="noreferrer"
                >
                  <button
                    disabled={calendarDateStartReg < currentDate() && !!editId}
                    type="button"
                    style={{
                      background: isHOGDomain() && "rgb(132, 100, 20)",
                    }}
                  >
                    <FormattedMessage
                      id="createTournament.title.btnRequest"
                      tagName="span"
                    />
                  </button>
                </a>
              </div>

              {/* Prize Fund */}
              <div className={s.descriptionContainer}>
                <div className={s.descriptionTitle}>
                  <FormattedMessage
                    id="createTournament.title.prize"
                    tagName="span"
                  />
                </div>
                {errors.prizeFund?.type === "minLength" && (
                  <span className={s.messageErr}>
                    <FormattedMessage
                      id="createLanding.err.errMessageLong"
                      tagName="span"
                    />
                  </span>
                )}
                <textarea
                  {...register("prizeFund", { minLength: 2 })}
                  className={s.descriptionTextarea}
                  placeholder={prizePlaceholder}
                  type="text"
                  style={{ background: isHOGDomain() && "#000" }}
                  disabled={calendarDateStartReg < currentDate() && !!editId}
                ></textarea>
              </div>

              {/* Layer*/}
              <div className={s.selectContainer}>
                <div className={s.text} style={{ marginBottom: 10 }}>
                  <FormattedMessage
                    id="createTournament.title.titleLayer"
                    tagName="span"
                  />
                </div>
                {errors.layer?.type === "required" && (
                  <p className={s.messageErr}>
                    <FormattedMessage
                      id="createTournament.title.titleObligatory"
                      tagName="span"
                    />
                  </p>
                )}
                <div
                  className={s.select}
                  style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
                >
                  <Controller
                    name="layer"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={layer}
                    render={({ field }) => (
                      <Select
                        onChange={(e) => {
                          field.onChange(e);
                          setLayer(e);
                        }}
                        classNamePrefix="custom_select"
                        options={layersOptions}
                        placeholder={layerPlaceholder}
                        isMulti={false}
                        value={layer}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        disabled={
                          calendarDateStartReg < currentDate() && !!editId
                        }
                      />
                    )}
                  />
                </div>
              </div>

              {/* Rules */}
              <div className={s.descriptionContainer}>
                <div className={s.descriptionTitle}>
                  <FormattedMessage
                    id="createTournament.title.rules"
                    tagName="span"
                  />
                </div>
                {errors.rules?.type === "minLength" && (
                  <span className={s.messageErr}>
                    <FormattedMessage
                      id="createLanding.err.errMessageLong"
                      tagName="span"
                    />
                  </span>
                )}
                <textarea
                  {...register("rules", { minLength: 2 })}
                  className={s.descriptionTextarea}
                  placeholder={rulesPlaceholder}
                  type="text"
                  style={{ background: isHOGDomain() && "#000" }}
                  disabled={calendarDateStartReg < currentDate() && !!editId}
                ></textarea>
              </div>
              <div
                style={{
                  display: "flex",
                  paddingBottom: 24,
                  justifyContent: "center",
                  marginTop: 46,
                }}
              >
                <div
                  className={s.tab}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",

                    background: "#201942",
                    width: "300px",
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => tabHandler("settings")}
                >
                  Next -&gt;
                </div>
                {/* <div
                
                  onClick={() => tabHandler("settings")}
                  style={{
                    height: 50,
                    fontSize: 18,
                    fontWeight: 500,
                    padding: 15,
                    width: 300,
                    background: isHOGDomain() && "rgb(132, 100, 20)",
                  }}
                >
                  Next -&gt;
                </div> */}
              </div>
            </div>
            {/* )} */}
            {/* {tabsForm === "settings" && ( */}
            <div
              className={
                tabsForm === "settings"
                  ? s.showSettingsBlockActive
                  : s.showSettingsBlockHidden
              }
            >
              {/* game */}
              <div className={s.selectContainer}>
                <div className={s.text} style={{ marginBottom: 10 }}>
                  <FormattedMessage
                    id="createTournament.title.titleGames"
                    tagName="span"
                  />
                </div>
                {errors.games?.type === "required" && (
                  <p className={s.messageErr}>
                    <FormattedMessage
                      id="createTournament.title.titleObligatory"
                      tagName="span"
                    />
                  </p>
                )}
                <div
                  className={s.select}
                  style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
                >
                  <Controller
                    name="games"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={games}
                    render={({ field }) => (
                      <GamesSelect
                        value={games}
                        handleChange={(e) => {
                          field.onChange(e);
                          setGames(e);
                        }}
                        disabled={
                          calendarDateStartReg < currentDate() && !!editId
                        }
                      />
                    )}
                  />
                </div>
              </div>

              <div className={s.selectContainer}>
                <div className={s.text} style={{ marginBottom: 10 }}>
                  <FormattedMessage
                    id="createTournament.title.titleSystem"
                    tagName="span"
                  />
                </div>
                {errors.system?.type === "required" && (
                  <p className={s.messageErr}>
                    <FormattedMessage
                      id="createTournament.title.titleObligatory"
                      tagName="span"
                    />
                  </p>
                )}
                <div
                  className={s.select}
                  style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
                >
                  <Controller
                    name="system"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={system}
                    render={({ field }) => (
                      <Select
                        onChange={(e) => {
                          field.onChange(e);
                          setSystem(e);
                        }}
                        classNamePrefix="custom_select"
                        options={createSystemOptions(SYSTEM, games.label)}
                        placeholder={systemPlaceholder}
                        isMulti={false}
                        value={system}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        disabled={
                          calendarDateStartReg < currentDate() && !!editId
                        }
                      />
                    )}
                  />
                </div>
              </div>
              {/* match format */}
              {!system?.name?.toLowerCase().includes("battle-royal") && (
                <div className={s.selectContainer}>
                  <div className={s.text} style={{ marginBottom: 10 }}>
                    <FormattedMessage
                      id="createTournament.title.matchesFormat"
                      tagName="span"
                    />
                  </div>
                  {errors.matchesFormat?.type === "required" && (
                    <p className={s.messageErr}>
                      <FormattedMessage
                        id="createTournament.title.titleObligatory"
                        tagName="span"
                      />
                    </p>
                  )}
                  <div
                    className={s.select}
                    style={{
                      background: isHOGDomain() && "rgb(132, 100, 20)",
                    }}
                  >
                    <Controller
                      name="matchesFormat"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={matchesFormat}
                      render={({ field }) => (
                        <Select
                          onChange={(e) => {
                            field.onChange(e);
                            setMatchesFormat(e);
                          }}
                          classNamePrefix="custom_select"
                          options={FORMAT}
                          value={matchesFormat}
                          placeholder={matchesFormatPlaceholder}
                          isMulti={false}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          disabled={
                            calendarDateStartReg < currentDate() && !!editId
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              {/* format */}
              <div className={s.selectContainer}>
                <div className={s.text} style={{ marginBottom: 10 }}>
                  <FormattedMessage
                    id="createTournament.title.format"
                    tagName="span"
                  />
                </div>
                {errors.format?.type === "required" && (
                  <p className={s.messageErr}>
                    <FormattedMessage
                      id="createTournament.title.titleObligatory"
                      tagName="span"
                    />
                  </p>
                )}
                <div
                  className={s.select}
                  style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
                >
                  <Controller
                    name="format"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={format}
                    render={({ field }) => (
                      <Select
                        onChange={(e) => {
                          field.onChange(e);
                          setFormat(e);
                        }}
                        classNamePrefix="custom_select"
                        options={createFormat(games.label, system.name)}
                        placeholder={formatPlaceholder}
                        isMulti={false}
                        value={format}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.backName}
                        disabled={
                          calendarDateStartReg < currentDate() && !!editId
                        }
                      />
                    )}
                  />
                </div>
              </div>

              {system.name === "Battle-royale (FFA)" &&
                (format.name === "duo" ||
                  format.name === "trio" ||
                  format.name === "squad") && (
                  <div className={s.selectContainer}>
                    <div className={s.text} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.titleSubstitutePlayer"
                        tagName="span"
                      />
                    </div>
                    {errors.substitutePlayer?.type === "required" && (
                      <p className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </p>
                    )}
                    <div
                      className={s.select}
                      style={{
                        background: isHOGDomain() && "rgb(132, 100, 20)",
                      }}
                    >
                      <Controller
                        name="substitutePlayer"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            onChange={(e) => {
                              field.onChange(e);
                              setSubstitutePlayer(e);
                            }}
                            classNamePrefix="custom_select"
                            options={[
                              { id: 1, name: "yes" },
                              { id: 2, name: "no" },
                            ]}
                            placeholder={substitutePlayerPlaceholder}
                            isMulti={false}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            defaultValue={[{ id: 2, name: "no" }]}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              {system.name === "Battle-royale (FFA)" && (
                <>
                  <div className={s.inputContainer}>
                    <div className={s.title} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.numOfStages"
                        tagName="span"
                      />
                    </div>
                    {errors.numOfStages?.type === "required" && (
                      <span className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </span>
                    )}
                    {errors.numOfStages?.type === "min" && (
                      <span className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.positiveNumErr"
                          tagName="span"
                        />
                      </span>
                    )}
                    <div className={classNames({ [s.textareaErr]: submitErr })}>
                      <input
                        {...register("numOfStages", {
                          required: true,
                          min: 1,
                        })}
                        type="number"
                        min="0"
                        placeholder={numOfStagesPlaceholder}
                        className={classNames({
                          [s.inputErr]: errors.numOfStages,
                        })}
                        onChange={(e) => setNumOfStages(+e.target.value)}
                        value={numOfStages}
                        disabled={
                          calendarDateStartReg < currentDate() &&
                          calendarDateStart > currentDate() &&
                          !!editId
                        }
                      />
                    </div>
                  </div>
                  <div className={s.inputContainer}>
                    <div className={s.title} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.numOfParticipantsInGroup"
                        tagName="span"
                      />
                    </div>
                    {checknumOfParticipantsInGroup(
                      games.label,
                      format.name,
                      numOfParticipantsInGroup
                    )}
                    {errors.numOfParticipantsInGroup?.type === "required" && (
                      <span className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </span>
                    )}
                    <div className={classNames({ [s.textareaErr]: submitErr })}>
                      <input
                        {...register("numOfParticipantsInGroup", {
                          required: true,
                        })}
                        type="number"
                        min="0"
                        placeholder={numOfStagesPlaceholder}
                        value={numOfParticipantsInGroup}
                        className={classNames({
                          [s.inputErr]: errors.numOfParticipantsInGroup,
                        })}
                        onChange={(e) =>
                          setNumOfParticipantsInGroup(+e.target.value)
                        }
                        disabled={
                          calendarDateStartReg < currentDate() &&
                          calendarDateStart > currentDate() &&
                          !!editId
                        }
                      />
                    </div>
                  </div>
                  <div className={s.inputContainer}>
                    <div className={s.title} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.numOfParticipantsToNextStage"
                        tagName="span"
                      />
                    </div>
                    {(numOfParticipantsToNextStage < 0 ||
                      numOfParticipantsToNextStage % 2 !== 0) && (
                      <p style={{ color: "red" }}>
                        <FormattedMessage
                          id="createTournament.error.positiveEvenNum"
                          tagName="span"
                        />
                      </p>
                    )}
                    {errors.numOfParticipantsToNextStage?.type ===
                      "required" && (
                      <span className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </span>
                    )}
                    <div className={classNames({ [s.textareaErr]: submitErr })}>
                      <input
                        {...register("numOfParticipantsToNextStage", {
                          required: true,
                        })}
                        type="number"
                        min="0"
                        value={numOfParticipantsToNextStage}
                        placeholder={numOfStagesPlaceholder}
                        className={classNames({
                          [s.inputErr]: errors.numOfParticipantsToNextStage,
                        })}
                        onChange={(e) =>
                          setNumOfParticipantsToNextStage(+e.target.value)
                        }
                        disabled={
                          calendarDateStartReg < currentDate() &&
                          calendarDateStart > currentDate() &&
                          !!editId
                        }
                      />
                    </div>
                  </div>
                  {numOfMapsForStage.map((item, idx) => {
                    return (
                      <div
                        key={`numOfMapsForStage-${idx}`}
                        className={s.inputContainer}
                      >
                        <div className={s.title} style={{ marginBottom: 10 }}>
                          <FormattedMessage
                            id="createTournament.title.numOfMaps"
                            tagName="span"
                          />
                          <span>{idx + 1}</span>
                        </div>
                        {(item > 10 || item < 0) && (
                          <p style={{ color: "red" }}>
                            <FormattedMessage
                              id="createTournament.error.num1to10"
                              tagName="span"
                            />
                          </p>
                        )}
                        <div
                          className={classNames({
                            [s.textareaErr]: submitErr,
                          })}
                        >
                          <input
                            type="number"
                            placeholder="Type number"
                            className={classNames({
                              [s.inputErr]: submitErr,
                            })}
                            value={item}
                            onChange={(e) => {
                              const newArr = [...numOfMapsForStage];
                              newArr[idx] = +e.target.value;
                              setNumOfMapsForStage(newArr);
                            }}
                            disabled={
                              calendarDateStartReg < currentDate() &&
                              calendarDateStart > currentDate() &&
                              !!editId
                            }
                            min="0"
                            // onBlur={chakedForm}
                          />
                        </div>
                      </div>
                    );
                  })}
                  {numOfParticipantsInGroup && (
                    <div style={{ marginTop: 46 }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          width: 210,
                        }}
                      >
                        <div style={{ width: 100, marginRight: 10 }}>
                          <p
                            style={{
                              fontWeight: 500,
                              fontSize: 17,
                              textAlign: "center",
                            }}
                          >
                            Place
                          </p>
                        </div>
                        <div style={{ width: 100 }}>
                          <p
                            style={{
                              fontWeight: 500,
                              fontSize: 17,
                              textAlign: "center",
                            }}
                          >
                            Score
                          </p>
                        </div>
                      </div>
                      <div>
                        {scoresForPlace.map((item, idx) => {
                          return (
                            <div key={`scoresForPlace-${idx}`}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                  width: 210,
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: 10,
                                    width: 100,
                                    textAlign: "center",
                                  }}
                                >
                                  {idx + 1}
                                </div>
                                <div
                                  className={s.inputContainer}
                                  style={{ width: 100, marginTop: 10 }}
                                >
                                  <div
                                    className={classNames({
                                      [s.textareaErr]: submitErr,
                                    })}
                                  >
                                    <input
                                      type="number"
                                      min="0"
                                      className={classNames({
                                        [s.inputErr]: submitErr,
                                      })}
                                      defaultValue={item}
                                      onChange={(e) => {
                                        const newArr = [...scoresForPlace];
                                        const itemToChange = newArr[idx];
                                        newArr[idx] = {
                                          ...itemToChange,
                                          value: +e.target.value,
                                        };
                                        setScoresForPlace(newArr);
                                      }}
                                      // onBlur={chakedForm}
                                      disabled={
                                        calendarDateStartReg < currentDate() &&
                                        calendarDateStart > currentDate() &&
                                        !!editId
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div
                    className={s.inputContainer}
                    style={{ marginBottom: 46 }}
                  >
                    <div className={s.title} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.numOfPointsForKills"
                        tagName="span"
                      />
                    </div>
                    {errors.numOfPointsForKills?.type === "required" && (
                      <span className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </span>
                    )}
                    {errors.numOfPointsForKills?.type === "min" && (
                      <span className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.positiveNumErr"
                          tagName="span"
                        />
                      </span>
                    )}
                    <div className={classNames({ [s.textareaErr]: submitErr })}>
                      <input
                        {...register("numOfPointsForKills", {
                          required: true,
                          min: 1,
                        })}
                        type="number"
                        value={numOfPointsForKills}
                        min="0"
                        onChange={(e) =>
                          setNumOfPointsForKills(+e.target.value)
                        }
                        placeholder={numOfPointsForKillsPlaceholder}
                        className={classNames({
                          [s.inputErr]: errors.numOfPointsForKills,
                        })}
                        disabled={
                          calendarDateStartReg < currentDate() &&
                          calendarDateStart > currentDate() &&
                          !!editId
                        }
                      />
                    </div>
                  </div>
                  <div className={s.selectContainer}>
                    <div className={s.text} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.tiebreak"
                        tagName="span"
                      />
                    </div>
                    {errors.tiebreak?.type === "required" && (
                      <p className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </p>
                    )}
                    <div
                      className={s.select}
                      style={{
                        background: isHOGDomain() && "rgb(132, 100, 20)",
                      }}
                    >
                      <Controller
                        name="tiebreak"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={tiebreak}
                        render={({ field }) => (
                          <Select
                            onChange={(e) => {
                              field.onChange(e);
                              setTiebreak(e);
                            }}
                            classNamePrefix="custom_select"
                            options={[
                              { id: 1, name: "yes" },
                              { id: 2, name: "no" },
                            ]}
                            isMulti={false}
                            getOptionLabel={(option) => option.name}
                            value={tiebreak}
                            getOptionValue={(option) => option.id}
                            disabled={
                              calendarDateStartReg < currentDate() &&
                              calendarDateStart > currentDate() &&
                              !!editId
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  {tiebreak.name === "yes" && (
                    <div>
                      <div
                        className={s.title}
                        style={{
                          marginBottom: 10,
                          fontWeight: 500,
                          fontSize: 18,
                        }}
                      >
                        <FormattedMessage
                          id="createTournament.title.draggableTiebreak"
                          tagName="span"
                        />
                      </div>
                      <Sortable
                        disabled={
                          calendarDateStartReg < currentDate() &&
                          calendarDateStart > currentDate() &&
                          !!editId
                        }
                        setTiebreakPriority={setTiebreakPriority}
                        options={tiebreakPriority}
                      />
                    </div>
                  )}
                </>
              )}
              <div className={s.inputContainer}>
                <div className={s.titleInput}>
                  <FormattedMessage
                    id="createTournament.title.titleMaximumNumber"
                    tagName="span"
                  />
                </div>
                {errors.maxNumber?.type === "required" && (
                  <span className={s.messageErr}>
                    <FormattedMessage
                      id="createTournament.title.titleObligatory"
                      tagName="span"
                    />
                  </span>
                )}
                {errors.maxNumber?.type === "min" && (
                  <span className={s.messageErr}>
                    <FormattedMessage
                      id="createTournament.title.positiveNumErr"
                      tagName="span"
                    />
                  </span>
                )}
                <input
                  {...register("maxNumber", {
                    min: 0,
                    required: true,
                  })}
                  type="number"
                  min="0"
                  placeholder={maximumNumberPlaceholder}
                  defaultValue={2}
                  value={maxNumber}
                  onChange={(e) => setMaxNumber(+e.target.value)}
                  disabled={calendarDateStartReg < currentDate() && !!editId}
                />
              </div>
              {system?.name?.toLowerCase().includes("round-robin") && (
                <div>
                  <div className={s.inputContainer}>
                    <div className={s.title} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.maxParticipantsInGroups"
                        tagName="span"
                      />
                    </div>
                    {maxNumInGroup > maxNumber && (
                      <p style={{ color: "red" }}>
                        <FormattedMessage
                          id="createTournament.error.maxParticipantsInGroups"
                          tagName="span"
                        />
                      </p>
                    )}
                    {errors.maxNumInGroup?.type === "min" && (
                      <span className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.positiveNumErr"
                          tagName="span"
                        />
                      </span>
                    )}
                    {errors.maxNumInGroup?.type === "required" && (
                      <span className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </span>
                    )}
                    <div className={classNames({ [s.textareaErr]: submitErr })}>
                      <input
                        {...register("maxNumInGroup", {
                          required: true,
                          min: 1,
                        })}
                        type="number"
                        min="0"
                        placeholder={maxNumInGroupPlaceholder}
                        className={classNames({ [s.inputErr]: submitErr })}
                        value={maxNumInGroup}
                        onChange={(e) => setMaxNumInGroup(+e.target.value)}
                        disabled={
                          calendarDateStartReg < currentDate() &&
                          calendarDateStart > currentDate() &&
                          !!editId
                        }
                      />
                    </div>
                  </div>
                  <div className={s.selectContainer}>
                    <div className={s.text} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.directSlotsPlaces"
                        tagName="span"
                      />
                    </div>
                    {errors.directSlots?.type === "required" && (
                      <p className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </p>
                    )}
                    <div
                      className={s.select}
                      style={{
                        background: isHOGDomain() && "rgb(132, 100, 20)",
                      }}
                    >
                      <Controller
                        name="directSlots"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={directSlots}
                        render={({ field }) => (
                          <Select
                            onChange={(e) => {
                              field.onChange(e);
                              setDirectSlots(e);
                            }}
                            classNamePrefix="custom_select"
                            options={createDirectSlots(maxNumInGroup)}
                            placeholder={directSlotPlacesPlaceholder}
                            isMulti={false}
                            value={directSlots}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            disabled={
                              calendarDateStartReg < currentDate() &&
                              calendarDateStart > currentDate() &&
                              !!editId
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className={s.selectContainer}>
                    <div className={s.text} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.secondChance"
                        tagName="span"
                      />
                    </div>
                    {errors.secondChance?.type === "required" && (
                      <p className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </p>
                    )}
                    <div
                      className={s.select}
                      style={{
                        background: isHOGDomain() && "rgb(132, 100, 20)",
                      }}
                    >
                      <Controller
                        name="secondChance"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={secondChance}
                        render={({ field }) => (
                          <Select
                            onChange={(e) => {
                              field.onChange(e);
                              setSecondChance(e);
                            }}
                            classNamePrefix="custom_select"
                            options={[
                              { id: 1, name: "yes" },
                              { id: 2, name: "no" },
                            ]}
                            placeholder={secondChancePlaceholder}
                            isMulti={false}
                            value={secondChance}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            // defaultValue={secondChance}
                            disabled={
                              calendarDateStartReg < currentDate() &&
                              calendarDateStart > currentDate() &&
                              !!editId
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  {secondChance.name === "yes" && (
                    <div className={s.selectContainer}>
                      <div className={s.text} style={{ marginBottom: 10 }}>
                        <FormattedMessage
                          id="createTournament.title.secondChanceSlots"
                          tagName="span"
                        />
                      </div>
                      {errors.secondChanceSlots?.type === "required" && (
                        <p className={s.messageErr}>
                          <FormattedMessage
                            id="createTournament.title.titleObligatory"
                            tagName="span"
                          />
                        </p>
                      )}
                      <div
                        className={s.select}
                        style={{
                          background: isHOGDomain() && "rgb(132, 100, 20)",
                        }}
                      >
                        <Controller
                          name="secondChanceSlots"
                          control={control}
                          rules={{ required: true }}
                          defaultValue={secondChanceSlots}
                          render={({ field }) => (
                            <Select
                              onChange={(e) => {
                                field.onChange(e);
                                setsecondChanceSlots(e);
                              }}
                              classNamePrefix="custom_select"
                              options={createSecondChanceSlots(
                                directSlots.name,
                                maxNumInGroup
                              )}
                              placeholder={secondChanceSlotsPlaceholder}
                              isMulti={false}
                              value={secondChanceSlots}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              disabled={
                                calendarDateStartReg < currentDate() &&
                                calendarDateStart > currentDate() &&
                                !!editId
                              }
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className={s.selectContainer}>
                <div className={s.text} style={{ marginBottom: 10 }}>
                  <FormattedMessage
                    id="createTournament.title.titleElegibilityRules"
                    tagName="span"
                  />
                </div>
                {errors.elegibilityRules?.type === "required" && (
                  <p className={s.messageErr}>
                    <FormattedMessage
                      id="createTournament.title.titleObligatory"
                      tagName="span"
                    />
                  </p>
                )}
                <div
                  className={s.select}
                  style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
                >
                  <Controller
                    name="elegibilityRules"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={elegibilityRules}
                    render={({ field }) => (
                      <Select
                        onChange={(e) => {
                          field.onChange(e);
                          setElegibilityRules(e);
                        }}
                        classNamePrefix="custom_select"
                        options={[
                          { id: 1, name: "yes" },
                          { id: 2, name: "no" },
                        ]}
                        placeholder={elegibilityRulesPlaceholder}
                        isMulti={false}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={elegibilityRules}
                        disabled={
                          calendarDateStartReg < currentDate() && !!editId
                        }
                      />
                    )}
                  />
                </div>
              </div>

              {elegibilityRules.name === "yes" && (
                <div>
                  <div className={s.inputContainer}>
                    <div className={s.titleInput}>
                      <FormattedMessage
                        id="createTournament.title.titleMinAge"
                        tagName="span"
                      />
                    </div>
                    <input
                      type="number"
                      placeholder={minAgePlaceholder}
                      value={minAge}
                      onChange={(e) => setMinAge(e.target.value)}
                      disabled={
                        calendarDateStartReg < currentDate() && !!editId
                      }
                      min="0"
                    />
                  </div>
                  <div className={s.selectContainer}>
                    <div className={s.text} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.titleGender"
                        tagName="span"
                      />
                    </div>
                    {errors.gender?.type === "required" && (
                      <p className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </p>
                    )}
                    <div
                      className={s.select}
                      style={{
                        background: isHOGDomain() && "rgb(132, 100, 20)",
                      }}
                    >
                      <Controller
                        name="gender"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={gender}
                        render={({ field }) => (
                          <Select
                            onChange={(e) => {
                              field.onChange(e);
                              setGender(e);
                            }}
                            classNamePrefix="custom_select"
                            options={GENDER}
                            placeholder={genderPlaceholder}
                            isMulti={false}
                            value={gender}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            disabled={
                              calendarDateStartReg < currentDate() && !!editId
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className={s.selectContainer}>
                    <div className={s.text} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.titleCountry"
                        tagName="span"
                      />
                    </div>
                    <div className={s.select}>
                      <SelectWithCheckboxes
                        value={country}
                        disabled={
                          calendarDateStartReg < currentDate() && !!editId
                        }
                        setState={setCountry}
                        options={countryOptions}
                        placeholder={countryPlaceholder}
                      />
                    </div>
                  </div>
                  <div className={s.selectContainer}>
                    <div className={s.text} style={{ marginBottom: 10 }}>
                      <FormattedMessage
                        id="createTournament.title.titleJudge"
                        tagName="span"
                      />
                    </div>
                    {errors.canJudge?.type === "required" && (
                      <p className={s.messageErr}>
                        <FormattedMessage
                          id="createTournament.title.titleObligatory"
                          tagName="span"
                        />
                      </p>
                    )}
                    <div
                      className={s.select}
                      style={{
                        background: isHOGDomain() && "rgb(132, 100, 20)",
                      }}
                    >
                      <Controller
                        name="canJudge"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={canJudge}
                        render={({ field }) => (
                          <Select
                            onChange={(e) => {
                              field.onChange(e);
                              setCanJudge(e);
                            }}
                            value={canJudge}
                            classNamePrefix="custom_select"
                            options={[
                              { id: 1, name: "yes" },
                              { id: 2, name: "no" },
                            ]}
                            placeholder={canJudgePlaceholder}
                            isMulti={false}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            defaultValue={[{ id: 2, name: "no" }]}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={s.mainSattingsContainer} style={{ marginTop: 0 }}>
                <div className={s.settingsContainer}>
                  <div
                    className={s.btnContainer}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className={s.btnInnerContainer}>
                      <div className={s.titleBtn}>
                        <FormattedMessage
                          id="createTournament.title.startTitle"
                          tagName="span"
                        />
                      </div>

                      <DateInput
                        onChange={setCalendarDateStart}
                        selected={calendarDateStart}
                      />
                      <div
                        className={s.inputContainer}
                        style={{ width: "100%" }}
                      >
                        <div className={s.titleInput}>Date Start Time</div>
                        {errors.dateStartTime?.type === "required" && (
                          <span className={s.messageErr}>
                            <FormattedMessage
                              id="createTournament.title.titleObligatory"
                              tagName="span"
                            />
                          </span>
                        )}
                        <input
                          {...register("dateStartTime", { required: true })}
                          type="time"
                          value={dateStartTime}
                          placeholder={matchStartPlaceholder}
                          onChange={(e) => setDateStartTime(e.target.value)}
                          // disabled={
                          //   calendarDateStartReg < currentDate() &&
                          //   calendarDateStart > currentDate() &&
                          //   !!editId
                          // }
                        />
                      </div>
                    </div>

                    <div className={s.btnInnerContainer}>
                      <div className={s.titleBtn}>
                        <FormattedMessage
                          id="createTournament.title.endTitle"
                          tagName="span"
                        />
                      </div>

                      <DateInput
                        onChange={setCalendarDateEnd}
                        selected={calendarDateEnd}
                      />
                      <div
                        className={s.inputContainer}
                        style={{ width: "100%" }}
                      >
                        <div className={s.titleInput}>Date Finish Time</div>
                        {errors.dateFinishTime?.type === "required" && (
                          <span className={s.messageErr}>
                            <FormattedMessage
                              id="createTournament.title.titleObligatory"
                              tagName="span"
                            />
                          </span>
                        )}
                        <input
                          {...register("dateFinishTime", { required: true })}
                          type="time"
                          value={dateFinishTime}
                          placeholder={matchStartPlaceholder}
                          onChange={(e) => setDateFinishTime(e.target.value)}
                          // disabled={
                          //   calendarDateStartReg < currentDate() &&
                          //   calendarDateStart > currentDate() &&
                          //   !!editId
                          // }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={s.mainSattingsContainer}
                style={{ marginTop: 50 }}
              >
                <div className={s.settingsContainer}>
                  <div
                    className={s.btnContainer}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className={s.btnInnerContainer}>
                      <div className={s.titleBtn}>
                        <FormattedMessage
                          id="createTournament.title.regStarTitle"
                          tagName="span"
                        />
                      </div>
                      <DateInput
                        onChange={setCalendarDateStartReg}
                        selected={calendarDateStartReg}
                      />
                      <div
                        className={s.inputContainer}
                        style={{ width: "100%" }}
                      >
                        <div className={s.titleInput}>
                          Regisrtation Start Time
                        </div>
                        {errors.registrationStartTime?.type === "required" && (
                          <span className={s.messageErr}>
                            <FormattedMessage
                              id="createTournament.title.titleObligatory"
                              tagName="span"
                            />
                          </span>
                        )}
                        <input
                          {...register("registrationStartTime", {
                            required: true,
                          })}
                          type="time"
                          value={registrationStartTime}
                          placeholder={matchStartPlaceholder}
                          onChange={(e) =>
                            setRegistrationStartTime(e.target.value)
                          }
                          // disabled={
                          //   calendarDateStartReg < currentDate() &&
                          //   calendarDateStart > currentDate() &&
                          //   !!editId
                          // }
                        />
                      </div>
                    </div>

                    <div className={s.btnInnerContainer}>
                      <div className={s.titleBtn}>
                        <FormattedMessage
                          id="createTournament.title.regEndTitle"
                          tagName="span"
                        />
                      </div>
                      <DateInput
                        onChange={setCalendarDateEndReg}
                        selected={calendarDateEndReg}
                      />
                      <div
                        className={s.inputContainer}
                        style={{ width: "100%" }}
                      >
                        <div className={s.titleInput}>
                          Regisrtation Finish Time{" "}
                        </div>
                        {errors.registrationFinishTime?.type === "required" && (
                          <span className={s.messageErr}>
                            <FormattedMessage
                              id="createTournament.title.titleObligatory"
                              tagName="span"
                            />
                          </span>
                        )}
                        <input
                          {...register("registrationFinishTime", {
                            required: true,
                          })}
                          type="time"
                          value={registrationFinishTime}
                          placeholder={matchStartPlaceholder}
                          onChange={(e) =>
                            setRegistrationFinishTime(e.target.value)
                          }
                          // disabled={
                          //   calendarDateStartReg < currentDate() &&
                          //   calendarDateStart > currentDate() &&
                          //   !!editId
                          // }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={
                  editId
                    ? {
                        border: "1px solid #201942",
                        borderRadius: 8,
                        marginTop: 46,
                        padding: 28,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                    : {}
                }
              >
                {editId && (
                  <div
                    className={s.titleInput}
                    style={{
                      marginBottom: 32,
                      fontSize: 20,
                      fontWeight: 500,
                    }}
                  >
                    <span>Fields for bracket</span>
                  </div>
                )}
                <div className={s.mainSattingsContainer}>
                  <div className={s.settingsContainer}>
                    <div
                      className={s.btnContainer}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div className={s.btnInnerContainer}>
                        <div
                          className={s.inputContainer}
                          style={editId ? { marginTop: 0 } : {}}
                        >
                          <div className={s.titleInput}>
                            <FormattedMessage
                              id="createTournament.title.titleStartTime"
                              tagName="span"
                            />
                          </div>
                          {errors.matchStart?.type === "required" && (
                            <span className={s.messageErr}>
                              <FormattedMessage
                                id="createTournament.title.titleObligatory"
                                tagName="span"
                              />
                            </span>
                          )}
                          <input
                            {...register("matchStart", { required: true })}
                            type="time"
                            value={matchStart}
                            placeholder={matchStartPlaceholder}
                            onChange={(e) => setMatchStart(e.target.value)}
                            disabled={
                              calendarDateStartReg < currentDate() &&
                              calendarDateStart > currentDate() &&
                              !!editId
                            }
                          />
                        </div>

                        <div
                          style={{ margin: "46px 0px" }}
                          className={s.inputContainer}
                        >
                          <div className={s.titleInput}>
                            <FormattedMessage
                              id="createTournament.title.titleStartDate"
                              tagName="span"
                            />
                          </div>
                          {errors.matchStartDate?.type === "required" && (
                            <span className={s.messageErr}>
                              <FormattedMessage
                                id="createTournament.title.titleObligatory"
                                tagName="span"
                              />
                            </span>
                          )}
                          <DateInput
                            onChange={setMatchStartDate}
                            selected={matchStartDate}
                            placeholderText={matchStartPlaceholder}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className={s.selectContainer}>
                  <div className={s.text} style={{ marginBottom: 10 }}>
                    <FormattedMessage
                      id="createTournament.title.titleMatchInterval"
                      tagName="span"
                    />
                  </div>
                  {errors.matchInterval?.type === "required" && (
                    <p className={s.messageErr}>
                      <FormattedMessage
                        id="createTournament.title.titleObligatory"
                        tagName="span"
                      />
                    </p>
                  )}
                  <div
                    className={s.select}
                    style={{
                      background: isHOGDomain() && "rgb(132, 100, 20)",
                    }}
                  >
                    <Controller
                      name="matchInterval"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={matchInterval}
                      render={({ field }) => (
                        <Select
                          onChange={(e) => {
                            field.onChange(e);
                            setMatchInterval(e);
                          }}
                          value={matchInterval}
                          classNamePrefix="custom_select"
                          options={[
                            { id: 1, name: 1 },
                            { id: 2, name: 2 },
                            { id: 3, name: 5 },
                            { id: 4, name: 10 },
                            { id: 5, name: 15 },
                            { id: 6, name: 30 },
                            { id: 7, name: 60 },
                          ]}
                          placeholder={matchIntervalPlaceholder}
                          isMulti={false}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          disabled={
                            calendarDateStartReg < currentDate() &&
                            calendarDateStart > currentDate() &&
                            !!editId
                          }
                        />
                      )}
                    />
                  </div>
                </div> */}
                <div className={s.inputContainer}>
                  <div className={s.titleInput}>
                    <FormattedMessage
                      id="createTournament.title.intervalBetweenRounds"
                      tagName="span"
                    />
                  </div>
                  {errors.intervalBetweenRounds?.type === "required" && (
                    <span className={s.messageErr}>
                      <FormattedMessage
                        id="createTournament.title.titleObligatory"
                        tagName="span"
                      />
                    </span>
                  )}
                  {errors.intervalBetweenRounds?.type === "min" && (
                    <span className={s.messageErr}>
                      <FormattedMessage
                        id="createTournament.title.positiveNumErr"
                        tagName="span"
                      />
                    </span>
                  )}
                  <input
                    type="number"
                    min="15"
                    defaultValue={intervalBetweenRounds}
                    {...register("intervalBetweenRounds", {
                      required: true,
                      min: 15,
                    })}
                    placeholder={numOfPointsForKillsPlaceholder}
                    onChange={(e) => setIntervalBetweenRounds(e.target.value)}
                    disabled={
                      calendarDateStartReg < currentDate() &&
                      calendarDateStart > currentDate() &&
                      !!editId
                    }
                  />
                </div>
                <div className={s.selectContainer}>
                  <div className={s.text} style={{ marginBottom: 10 }}>
                    Required CHECK-IN
                  </div>
                  {errors.requiredCheckIn?.type === "required" && (
                    <p className={s.messageErr}>
                      <FormattedMessage
                        id="createTournament.title.titleObligatory"
                        tagName="span"
                      />
                    </p>
                  )}
                  <div
                    className={s.select}
                    style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
                  >
                    <Controller
                      name="requiredCheckIn"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={requiredCheckIn}
                      render={({ field }) => (
                        <Select
                          onChange={(e) => {
                            field.onChange(e);
                            setRequiredCheckIn(e);
                          }}
                          classNamePrefix="custom_select"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          placeholder={"Yes / No"}
                          isMulti={false}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={requiredCheckIn}
                          disabled={
                            calendarDateStartReg < currentDate() && !!editId
                          }
                        />
                      )}
                    />
                  </div>
                </div>
                <div className={s.selectContainer}>
                  <div className={s.text} style={{ marginBottom: 10 }}>
                    Autocomplete match-ticker
                  </div>
                  {errors.autocompleteMatchType?.type === "required" && (
                    <p className={s.messageErr}>
                      <FormattedMessage
                        id="createTournament.title.titleObligatory"
                        tagName="span"
                      />
                    </p>
                  )}
                  <div
                    className={s.select}
                    style={{ background: isHOGDomain() && "rgb(132, 100, 20)" }}
                  >
                    <Controller
                      name="autocompleteMatchType"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={autocompleteMatchType}
                      render={({ field }) => (
                        <Select
                          onChange={(e) => {
                            field.onChange(e);
                            setAutocompleteMatchType(e);
                          }}
                          classNamePrefix="custom_select"
                          options={[
                            {
                              id: "start3hours",
                              name: "In 3 hours after a match start",
                            },
                            { id: "midnightUTC", name: "23:59:59 match day" },
                            { id: "manually", name: "Manually" },
                          ]}
                          placeholder={"Choose Autocomplete Type"}
                          isMulti={false}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={autocompleteMatchType}
                          disabled={
                            calendarDateStartReg < currentDate() && !!editId
                          }
                        />
                      )}
                    />
                  </div>
                </div>
                {/* <div className={s.inputContainer}>
                  <div className={s.titleInput}>
                    <FormattedMessage
                      id="createTournament.title.titleMatchesInInterval"
                      tagName="span"
                    />
                  </div>
                  {errors.matchesInInterval?.type === "required" && (
                    <span className={s.messageErr}>
                      <FormattedMessage
                        id="createTournament.title.titleObligatory"
                        tagName="span"
                      />
                    </span>
                  )}
                  {errors.matchesInInterval?.type === "min" && (
                    <span className={s.messageErr}>
                      <FormattedMessage
                        id="createTournament.title.positiveNumErr"
                        tagName="span"
                      />
                    </span>
                  )}
                  <input
                    type="number"
                    min="0"
                    value={matchesInInterval}
                    {...register("matchesInInterval", {
                      required: true,
                      min: 1,
                    })}
                    placeholder={matchesInIntervalPlaceholder}
                    onChange={(e) => setMatchesInInterval(+e.target.value)}
                    disabled={
                      calendarDateStartReg < currentDate() &&
                      calendarDateStart > currentDate() &&
                      !!editId
                    }
                  />
                </div> */}
                {/*{editId && <button type='button' style={{ marginTop: 32, background: isHOGDomain() && "rgb(132, 100, 20)" }}>Update bracket</button>}*/}
              </div>
              <div
                style={{
                  display: "flex",
                  paddingBottom: 24,
                  justifyContent: "center",
                  marginTop: 46,
                }}
              >
                <button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  style={{
                    height: 50,
                    fontSize: 18,
                    fontWeight: 500,
                    padding: 15,
                    width: 300,
                    background: isHOGDomain() && "rgb(132, 100, 20)",
                  }}
                >
                  {!editId ? "Create tournament" : "Edit tournament"}
                </button>
              </div>
            </div>

            {/* )} */}
          </div>
        </form>
      </div>
    </Container>
  );
};
export default CreateTournament;
