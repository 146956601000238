import React from "react";
import { ButtonRow, Button, Modal, ModalSubTitle } from "../UI";
import { useDispatch } from "react-redux";
import { hideNotificationModal } from "../../redux/actions";
import { FormattedMessage } from "react-intl";
import { isHOGDomain } from "../../comon/isHOGDomain";

import styles from "./styles.module.scss";

const NotificationModal = ({ isOpen, message }) => {
  const dispatch = useDispatch();

  return (
    <Modal open={isOpen} isTransparent={true}>
      <div className={isHOGDomain() ? styles.modal : styles.modalHOG}>
        <ModalSubTitle style={{ fontSize: 18, fontWeight: 500 }}>
          {message}
        </ModalSubTitle>
        <ButtonRow direction="center">
          <Button
            variant="secondary"
            label={<FormattedMessage id="global.buttons.ok" />}
            action={() => dispatch(hideNotificationModal())}
          />
        </ButtonRow>
      </div>
    </Modal>
  );
};

export default NotificationModal;
