export const loaderTexts = [
    'Associated Press in 2017 has indicated for the AP Style Guide, the correct spelling is “esports” due to “industry trends and general usage” and capitalized as “Esports” if used at the beginning of a sentence. However, other versions of spelling are still in use',
    'One of the first documented competitive gaming experience are connected to the the space game "Spacewar". It had already been written in 1962 on a PDP-10 computer by computer scientist Steve Russel and some colleagues like Martin Graetz and Wayne Wiitanen of the "Tech Model Railroad Club" at MIT. In it, two players play against each other with one spaceship each',
    'The first official video game competition took place on October 19, 1972 at the Artificial Intelligence Laboratory at Stanford University. The “Intergalactic Spacewar Olympics” hosted 24 participants, that competed in a game Spacewar. The top prize was a one year’s subscription on Rolling Stone magazine',
    'On October 10th, 1980, William Salvador Heineman was crowned the winner of the Space Invaders Championships. The early gaming classic was played at the over 10,000 gamers competing to win a version of Asteroids.  William Salvador was thus the first winner of a national esports competition',
    'The first multiplayer computer game that up to 16 players could play against each other over the Internet was released in 1988. The game was Netrek - it was a real-time strategy game in the Star Trek universe. Netrek was played all over the world, but it was mainly computer scientists, since it was scientific institutions that had access to the early Internet',
    'The first esports leagues were founded in the end of the 1990s: For example, the Electronic Sports League, which emerged from the "German Clan League" (DeCL), or the "ClanBase", which was launched in 1998. FVC league was launched in Belarus in 2000 and became one of the first professional sim-racing leagues',
    'First professional attempt to institutionalize esports happened In South Korea with founding of the Korean e-Sports Association (KeSPA) in 2000, which from the very beginning focused on the possibilities of marketing esports on TV. Since that moment, South Korea became a leader in terms of spreading of the esports among masses',
    'June 19th, 1999 is The day for esports fans - Counter-Strike has been released as the result of a small team of students. The gameplay is all about the fight of an anti-terrorist unit against terrorists on a limited map. The game spread rapidly and became a real multiplayer hit - and is still one of the most successful games in the history of esport, gathering full stadiums',
    'The first "World Cyber Games" (WCG) were held in Seoul in 2000. In 2003, the first Electronic Sports World Cup (ESWC) was played in Poitiers, France. While the initial focus was on PC games, console titles were gradually included in the competition',
    'First esports tournament with “over a million prize pool” happened in 2011 - Valve during Gamescom has organized competition on Dota 2 called The International. The first edition was won by Ukrainian team NAVI. To the date, The International has the largest prize pool – 2021 edition had 40M USD in total'
]