import React, { useState, useEffect, useRef } from "react";
import { cloneDeep } from "lodash";
import classNames from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import Select from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import { useSelector, useDispatch } from "react-redux";

import {
  addSpinner,
  removeSpinner,
  showNotificationModal,
} from "../../../redux/actions";

import { API, API_ROUTER } from "../../../api/index";

import DomenInput from "./components/domenInput";
import TextArea from "./components/textArea";
import InputComponent from "./components/input";
import TooltipImg from "./components/tooltip";
import { uploadImg, bracket } from "../../../comon/comonConst";
import { isHOGDomain } from "../../../comon/isHOGDomain";
import FileUploaderSingle from "../../../comon/file-uploader-for-single";
import handleSubmit from "./constant/submit";
import {
  uploadData,
  onChangeDudlePhotos,
  addPhotos,
  delPhoto,
} from "../../../comon/uploadData";

import * as images from "./images";

import {
  constformFields,
  initialErrors,
  MAX_LANGUAGES_COUND,
  REQUIRED_LANGUIGE_NAME,
} from "./const";

import { setValueToSelect } from "../../../helpers";

import {
  aboutLanding,
  buttons,
  textTitle,
  pageTitles,
  registrationWindow,
  registrationErrors,
} from "./sections";
import s from "./createLanding.module.scss";
import "../../../comon/CustomSelect/select.scss";

const CreateLanding = (props) => {
  const { id: editId } = useParams();

  const ref = useRef();
  const dispatch = useDispatch();

  const ServerAnswerSuccess = useIntl().formatMessage({
    id: `${
      editId
        ? "createLanding.serverAnswer.success.edit"
        : "createLanding.serverAnswer.success.create"
    }`,
  });

  const languagesList = useSelector((state) => state.languagesList);
  const countriesList = useSelector((state) => state.countriesList);

  const [landingData, setLandingData] = useState({});
  const [formFields, setFormFields] = useState();
  const [errors, setErrors] = useState(initialErrors);
  const [errorField, setErrorField] = useState("");
  const [languages, setLanguages] = useState([]);
  const [updateComponent, setUpdateSelectLanguagesValue] = useState("");
  const [selectLanguages, setSelectLanguages] = useState([]);
  const [languagesBack, setLanguagesBack] = useState([]);
  const [langForMainLangs, setLangForMainLangs] = useState([]);
  const [photoArrOrganizerlogo, setInputArrOrganizerlogo] = useState(["1"]);
  const [photoArrSponsorlogo, setInputArrSponsorlogo] = useState(["1"]);
  const [photoArrPartnerlogo, setInputArrPartnerlogo] = useState(["1"]);
  const [domainSelection, setDomainSelection] = useState("");
  // СТЕЙТ
  function setLandingState() {
    setFormFields(
      constformFields(
        landingData,
        setValueToSelect,
        getlanguageFromValue,
        getCountryFromValue
      )
    );
  }

  const history = useHistory();
  function goToUrl(url) {
    history.push(url);
  }

  const changeValueLanguages = (e) => {
    if (e.length >= MAX_LANGUAGES_COUND) {
      const value = selectLanguages.splice(0, MAX_LANGUAGES_COUND);
      return setSelectLanguages(value);
    }
    setSelectLanguages(e);
  };

  const scrollToErrorField = () => {
    setImmediate(() =>
      ref.current.scrollIntoView({ behavior: "smooth", inline: "center" })
    );
  };

  // // ВВОД СЕЛЕКТА
  function selectHandler(value, selectName) {
    setFormFields({ ...formFields, [selectName]: value });
  }

  // // ВВОД ИНПУТА
  function inputHandler(e) {
    const { name, value } = e.target;
    if (name === "domain") {
      setFormFields({
        ...formFields,
        [name]: value.replace(/^[a-z0-9_-]+$/),
      });
    }

    setFormFields({
      ...formFields,
      [name]: value,
    });
  }

  // // ВВОД FAVICON

  function imageHandler(e, name) {
    setFormFields({
      ...formFields,
      [name]: e,
    });
  }

  // // СДЕЛАТЬ ДЛЯ СЕЛЕКТА СПИСОК
  function getlanguageFromValue(language) {
    if (Array.isArray(language)) {
      return language.map((el) =>
        languagesList.find((item) => item?.value === el)
      );
    } else {
      const keysMap = {
        value: "name",
        label: "value",
      };
      const renameKeys = (keysMap, obj) =>
        Object.keys(obj || {}).reduce(
          (acc, key) => ({
            ...acc,
            ...{ [keysMap[key] || key]: obj[key] },
          }),
          {}
        );
      const oldObj = languagesList?.find((item) => item?.value === language);
      return renameKeys(keysMap, oldObj);
    }
  }

  function getCountryFromValue(country) {
    if (Array.isArray(country)) {
      return country.map((el) =>
        countriesList.find((item) => item?.value === el)
      );
    } else {
      return countriesList.find((item) => item?.value === country);
    }
  }

  const getLandingData = () => {
    if (editId) {
      dispatch(addSpinner());

      let params = {
        ...API_ROUTER.landing.getDataLanding,
        pathKeys: {
          landingId: editId,
        },
      };
      return API.request(params)
        .then((response) => {
          setLandingData(response);
          setInputArrOrganizerlogo(response?.landingOrganizePartnerLogo);
          setInputArrSponsorlogo(response?.landingSponsorLogo);
          setInputArrPartnerlogo(response?.landingPartnerLogo);
          setSelectLanguages(defaultTextValues(languages, response));

          setDomainSelection(response?.domainSelection || "free");
          dispatch(removeSpinner());
        })
        .catch((err) => {
          toast.error("something went wrong");
          dispatch(removeSpinner());
        });
    }
  };
  const changeLanguagesValue = (target, id, elName, checkFn) => {
    setUpdateSelectLanguagesValue(target.value);

    selectLanguages.forEach((el) => {
      if (el.id === id) {
        el[elName] = target.value;
      }
    });
  };
  const defaultTextValues = (languages, res) => {
    const selectedLang = languages.filter((lang) =>
      res.languages.includes(lang.name.toLowerCase())
    );
    const newArr = selectedLang.map((item) => {
      const newItem = cloneDeep(item);
      for (let key in newItem) {
        if (key !== "id" && key !== "name" && key !== "value") {
          for (let prop in res) {
            if (key === prop && res[prop] !== null) {
              let response;
              res[prop].forEach((res) => {
                for (let language in res) {
                  if (language === newItem.name) {
                    response = res[language];
                  }
                }
              });
              newItem[key] = response;
            }
          }
        }
      }
      return newItem;
    });
    return newArr;
  };
  const getLanguages = () => {
    API.request({ ...API_ROUTER.landing.getLanguagesLanding })
      .then((res) => {
        let languagesArr = [];

        for (let key in res) {
          let newObj = {
            id: languagesArr.length + 1,
            name: key.toUpperCase(),
            value: res[key],
            textTitle: "",
            textDescription: "",
            textBlockSuccessMsg: "",
            textBlockContent1: "",
            textBlockContent2: "",
            textBlockAbout1: "",
            textBlockAbout2: "",
            textBlockAbout3: "",
            textBlockAbout4: "",
            textBlockAbout5: "",
            textBlockAbout6: "",
            textBlockAbout7: "",
            textBlockAbout8: "",
            textBlockAbout9: "",
            textBlockAbout10: "",
            textBlockAbout11: "",
            textBlockAbout12: "",
            textBlockAbout13: "",
            textBlockAbout14: "",
            textBlockAbout15: "",
            textBlockAbout16: "",
            textBlockAbout17: "",
            textBlockAbout18: "",
            textBlockAbout19: "",
            textBlockAbout20: "",
            textBlockAbout21: "",
            textBlockAbout22: "",
            textBlockAbout23: "",
            textBlockAbout24: "",
            textBlockAbout25: "",
            textBlockAbout26: "",
            textBlockAbout27: "",
            textBlockAbout28: "",
            textBlockAbout29: "",
            textBlockAbout30: "",
            textBlockOrganizer1: "",
            textBlockOrganizer2: "",
            textBlockOrganizer3: "",
            textBlockButtons1: "",
            textBlockButtons2: "",
            textBlockButtons3: "",
            textBlockButtons4: "",
            textBlockButtons5: "",
            textBlockRegistration1: "",
            textBlockRegistration2: "",
            textBlockRegistration3: "",
            textBlockRegistration4: "",
            textBlockRegistration5: "",
            textBlockRegistration6: "",
            textBlockRegistration7: "",
            textBlockRegistration8: "",
            textBlockRegistration9: "",
            textBlockRegistration10: "",
            textBlockRegistration11: "",
            textBlockRegistration12: "",
            textBlockRegistration13: "",
            textBlockRegistration14: "",
            textBlockTitle1: "",
            textBlockTitle2: "",
            textBlockTitle3: "",
            textBlockTitle4: "",
            textBlockRegisterError1: "",
            textBlockRegisterError2: "",
            textBlockRegisterError3: "",
          };

          languagesArr.push(newObj);
        }

        const filterArr = languagesArr.filter(
          (el) => el.name !== REQUIRED_LANGUIGE_NAME
        );
        const englishLang = languagesArr.filter(
          (el) => el.name === REQUIRED_LANGUIGE_NAME
        );
        setLangForMainLangs([...selectLanguages, ...englishLang]);
        setLanguages(filterArr);
        setLanguagesBack(languagesArr);
      })
      .catch((err) => toast.error("something went wrong"));
  };

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    getLandingData();
  }, [editId, languages.length, languagesBack.length]);

  useEffect(() => {
    if (landingData) {
      return setLandingState();
    } else {
      setFormFields({ ...formFields });
    }
  }, [landingData]);

  return (
    <div>
      <div className={s.title}>
        {editId ? (
          <FormattedMessage id="editLanding.title.pageName" tagName="span" />
        ) : (
          <FormattedMessage id="createLanding.title.pageName" tagName="span" />
        )}
        {/* Add country */}
        <div
          className={s.selectContainer}
          ref={errorField === "country" ? ref : null}
        >
          <div className={s.text}>
            <FormattedMessage id="createLanding.title.country" tagName="span" />
            <TooltipImg image={images.country} />
          </div>

          <div>
            <div
              className={s.select}
              style={{ background: `${isHOGDomain() && "#846414"}` }}
            >
              <Select
                required
                style={{ fontSize: 18 }}
                classNamePrefix="custom_select"
                options={countriesList || []}
                value={formFields?.country}
                onChange={(e) => selectHandler(e, "country")}
              />
            </div>
          </div>
        </div>
        {/* ADD LANGUAGES */}
        <div className={s.selectContainer}>
          <div className={s.text}>
            <FormattedMessage
              id="createLanding.title.language"
              tagName="span"
            />
            <TooltipImg image={images.language} />
          </div>
          <div>
            <div
              className={s.select}
              style={{ background: `${isHOGDomain() && "#846414"}` }}
            >
              <Select
                style={{ fontSize: 18 }}
                classNamePrefix="custom_select"
                options={languages || []}
                placeholder="languages"
                isMulti={true}
                name="language"
                value={selectLanguages}
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => option.name}
                onChange={changeValueLanguages}
              />
            </div>
          </div>
        </div>
        {selectLanguages.length >= 1 && (
          <div className={s.selectContainer}>
            <div className={s.text}>
              <FormattedMessage
                id="createLanding.title.mainLanguage"
                tagName="span"
              />
            </div>

            <div
              className={s.select}
              style={{ background: `${isHOGDomain() && "#846414"}` }}
            >
              <Select
                classNamePrefix="custom_select"
                options={[...langForMainLangs, ...selectLanguages]}
                placeholder="main language"
                isMulti={false}
                name="mainLanguage"
                value={formFields?.mainLandingLang}
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => option.name}
                onChange={(option) => selectHandler(option, "mainLandingLang")}
              />
            </div>
          </div>
        )}
        {/* LANDINT TITLE */}
        {/* <div ref={errorField === "textTitle" ? ref : null}>
          <InputComponent
            element={formFields?.textTitle}
            inputHandler={inputHandler}
            selectLanguages={selectLanguages}
            selectLanguagesElement={"textTitle"}
            changeLanguagesValue={changeLanguagesValue}
            formattedMessageInputId={"createLanding.title.landingTitle"}
          />
        </div> */}
        {/* LANDING DESCRIPTION */}
        {/* <div ref={errorField === "textDescription" ? ref : null}>
          <InputComponent
            element={formFields?.textDescription}
            inputHandler={inputHandler}
            selectLanguages={selectLanguages}
            selectLanguagesElement={"textDescription"}
            changeLanguagesValue={changeLanguagesValue}
            formattedMessageInputId={"createLanding.title.landingDescription"}
          />
        </div> */}

        {/* UPLOAD LANDINGS FAVICON */}
        {/* <div className={s.imageContainer}>
          <div className={`${isHOGDomain() ? s.imgTitleHOG : s.imgTitle}`}>
            <FormattedMessage
              id="createLanding.title.addFavicon"
              tagName="div"
            />
          </div>

          <div className={s.btnContainer}>
            <FileUploaderSingle
              style={{ marginTop: 0 }}
              defaultFoto={uploadImg}
              setImageURL={(e) => imageHandler(e, "landingFavicon")}
              imageURL={
                formFields?.landingFavicon ? formFields?.landingFavicon : ""
              }
              htmlFor={"landingFavicon"}
            />
          </div>
        </div> */}
        {/* UPLOAD LANDINGS BANNER */}
        {/* <div className={s.imageContainer}>
          <div className={`${isHOGDomain() ? s.imgTitleHOG : s.imgTitle}`}>
            <FormattedMessage
              id="createLanding.title.uploadBanner"
              tagName="div"
            />
          </div>

          <div className={s.btnContainer}>
            <FileUploaderSingle
              style={{ marginTop: 0 }}
              defaultFoto={uploadImg}
              setImageURL={(e) => imageHandler(e, "landingPageHeaderBanner")}
              imageURL={
                formFields?.landingPageHeaderBanner
                  ? formFields?.landingPageHeaderBanner
                  : ""
              }
              htmlFor={"bannerImg"}
            />
          </div>
        </div> */}
        {/* UPLOAD LANDINGS LOGO */}
        <div className={s.imageContainer}>
          <div className={s.tooltipContainer}>
            <div className={`${isHOGDomain() ? s.imgTitleHOG : s.imgTitle}`}>
              <FormattedMessage
                id="createLanding.title.landingLogo"
                tagName="div"
              />
            </div>
            <TooltipImg image={images.logo} />
          </div>

          <div className={s.btnContainer}>
            <FileUploaderSingle
              style={{ marginTop: 0 }}
              defaultFoto={uploadImg}
              setImageURL={(e) => imageHandler(e, "landingLogo")}
              imageURL={formFields?.landingLogo ? formFields?.landingLogo : ""}
              htmlFor={"logoLanding"}
            />
          </div>
        </div>
        {/* UPLOAD LANDINGS ORGANIZER LOGO */}
        {/* <div className={s.imageContainer}>
          <div className={s.tooltipContainer}>
            <div className={`${isHOGDomain() ? s.imgTitleHOG : s.imgTitle}`}>
              <FormattedMessage
                id="createLanding.title.organizerLogo"
                tagName="div"
              />
            </div>
            <HtmlTooltip
              placement="right"
              title={
                <React.Fragment>
                  <br />
                  <Typography color="inherit">How it looks like</Typography>
                  <br />
                  <img
                    alt="img"
                    style={{ height: "400px" }}
                  ></img>
                  <br />
                  <p>
                    Choose image <b>{"4x3"}</b> format
                  </p>{" "}
                  <br />
                  <u>
                    {
                      "If you don't like it you can change the picture at any time"
                    }
                  </u>
                  .
                </React.Fragment>
              }
            >
              <div className={s.tooltip}>?</div>
            </HtmlTooltip>
          </div>

          {photoArrOrganizerlogo?.map((el, i) => (
            <div>
              <div key={i} className={s.btnContainer}>
                <div
                  className={s.delBtn}
                  onClick={() =>
                    delPhoto(
                      i,
                      setInputArrOrganizerlogo,
                      photoArrOrganizerlogo,
                      editId
                    )
                  }
                ></div>
                <FileUploaderSingle
                  style={{ marginTop: 0 }}
                  defaultFoto={uploadImg}
                  imageURL={photoArrOrganizerlogo[i]}
                  htmlFor={`organizerLogo${i}`}
                  isMany={true}
                  onChangeDudlePhotos={onChangeDudlePhotos}
                  id={i}
                  fn={setInputArrOrganizerlogo}
                  data={photoArrOrganizerlogo}
                />
              </div>
            </div>
          ))}
          <div className={s.addBtnContainer}>
            <button
              style={{ background: `${isHOGDomain() && "#846414"}` }}
              className={s.addBtn}
              onClick={(e) =>
                addPhotos(photoArrOrganizerlogo, setInputArrOrganizerlogo)
              }
            >
              <FormattedMessage
                id="createLanding.title.addorganizerLogo"
                tagName="span"
              />
            </button>
          </div>
        </div> */}
        {/* UPLOAD LANDINGS SPONSOR LOGO */}
        {/* <div className={s.imageContainer}>
          <div className={s.tooltipContainer}>
            <div className={`${isHOGDomain() ? s.imgTitleHOG : s.imgTitle}`}>
              <FormattedMessage
                id="createTournament.title.imgTitle.sponsorLogo"
                tagName="div"
              />
            </div>
            <HtmlTooltip
              placement="right"
              title={
                <React.Fragment>
                  <br />
                  <Typography color="inherit">How it looks like</Typography>
                  <br />
                  <img
                    alt="img"
                    style={{ height: "400px" }}
                  ></img>
                  <br />
                  <p>
                    Choose image <b>{"4x3"}</b> format
                  </p>{" "}
                  <br />
                  <u>
                    {
                      "If you don't like it you can change the picture at any time"
                    }
                  </u>
                  .
                </React.Fragment>
              }
            >
              <div className={s.tooltip}>?</div>
            </HtmlTooltip>
          </div>

          {photoArrSponsorlogo.map((el, i) => (
            <div>
              <div key={i} className={s.btnContainer}>
                <div
                  className={s.delBtn}
                  onClick={() =>
                    delPhoto(
                      i,
                      setInputArrSponsorlogo,
                      photoArrSponsorlogo,
                      editId
                    )
                  }
                ></div>
                <FileUploaderSingle
                  style={{ marginTop: 0 }}
                  defaultFoto={uploadImg}
                  imageURL={photoArrSponsorlogo[i]}
                  htmlFor={`sponsor${i}`}
                  isMany={true}
                  onChangeDudlePhotos={onChangeDudlePhotos}
                  id={i}
                  fn={setInputArrSponsorlogo}
                  data={photoArrSponsorlogo}
                />
              </div>
            </div>
          ))}

          <div className={s.addBtnContainer}>
            <button
              style={{ background: `${isHOGDomain() && "#846414"}` }}
              className={s.addBtn}
              onClick={() =>
                addPhotos(photoArrSponsorlogo, setInputArrSponsorlogo)
              }
            >
              <FormattedMessage
                id="createLanding.title.addsponsorLogo"
                tagName="span"
              />
            </button>
          </div>
        </div> */}
        {/* UPLOAD LANDINGS PARTNERS LOGO */}
        {/* <div className={s.imageContainer}>
          <div className={s.tooltipContainer}>
            <div className={`${isHOGDomain() ? s.imgTitleHOG : s.imgTitle}`}>
              <FormattedMessage
                id="createLanding.title.partnersLogo"
                tagName="div"
              />
            </div>
            <HtmlTooltip
              placement="right"
              title={
                <React.Fragment>
                  <br />
                  <Typography color="inherit">How it looks like</Typography>
                  <br />
                  <img
                    alt="img"
                    style={{ height: "400px" }}
                  ></img>
                  <br />
                  <p>
                    Choose image <b>{"4x3"}</b> format
                  </p>{" "}
                  <br />
                  <u>
                    {
                      "If you don't like it you can change the picture at any time"
                    }
                  </u>
                  .
                </React.Fragment>
              }
            >
              <div className={s.tooltip}>?</div>
            </HtmlTooltip>
          </div>

          {photoArrPartnerlogo.map((el, i) => (
            <div>
              <div key={i} className={s.btnContainer}>
                <div
                  className={s.delBtn}
                  onClick={() =>
                    delPhoto(
                      i,
                      setInputArrPartnerlogo,
                      photoArrPartnerlogo,
                      editId
                    )
                  }
                ></div>
                <FileUploaderSingle
                  style={{ marginTop: 0 }}
                  defaultFoto={uploadImg}
                  imageURL={photoArrPartnerlogo[i]}
                  htmlFor={`partners${i}`}
                  isMany={true}
                  onChangeDudlePhotos={onChangeDudlePhotos}
                  id={i}
                  fn={setInputArrPartnerlogo}
                  data={photoArrPartnerlogo}
                />
              </div>
            </div>
          ))}

          <div className={s.addBtnContainer}>
            <button
              style={{ background: `${isHOGDomain() && "#846414"}` }}
              className={s.addBtn}
              onClick={() =>
                addPhotos(photoArrPartnerlogo, setInputArrPartnerlogo)
              }
            >
              <FormattedMessage
                id="createLanding.title.addpartnersLogo"
                tagName="span"
              />
            </button>
          </div>
        </div> */}
        {/* ADD DOMEN */}
        <DomenInput
          ref={ref}
          element={formFields?.domain}
          inputHandler={inputHandler}
          selectHandler={selectHandler}
          formattedMessageDomainOptionsId="createLanding.message.p1"
          formattedMessageDomainTypeFree="createLanding.domainName.typeFree"
          formattedMessageDomainTypePremium="createLanding.domainName.typePremium"
          formattedMessageDomainExtra="createLanding.message.premium"
          errorField={errorField}
          tooltipImage={images.domen}
          domainSelection={domainSelection}
          setDomainSelection={setDomainSelection}
        />
        {/* ADD TEXT TITLE CONTENT  */}
        <div className={s.textTitle} style={{ marginTop: 46, fontWeight: 600 }}>
          <FormattedMessage id="createLanding.title.content" tagName="p" />
        </div>
        {textTitle(
          ref,
          formFields,
          inputHandler,
          selectLanguages,
          changeLanguagesValue,
          errorField
        )}
        {/* ADD TEXT BLOCK ABOUT */}
        <div className={s.textTitle} style={{ marginTop: 46, fontWeight: 600 }}>
          <FormattedMessage id="createLanding.title.about" tagName="p" />
        </div>
        {aboutLanding(
          ref,
          formFields,
          inputHandler,
          selectLanguages,
          changeLanguagesValue,
          errorField
        )}
        {/* BUTTONS */}
        <div className={s.textTitle} style={{ marginTop: 46, fontWeight: 600 }}>
          <FormattedMessage id="createLanding.title.buttons" tagName="h1" />
        </div>
        <div style={{ marginTop: -15, fontSize: 10 }}>
          <FormattedMessage id="createLanding.tip.can" tagName="p" />
        </div>
        {buttons(
          ref,
          formFields,
          inputHandler,
          selectLanguages,
          changeLanguagesValue,
          errorField
        )}
        {/* PAGE TITLES */}
        {/* <div className={s.textTitle} style={{ marginTop: 46, fontWeight: 600 }}>
          <FormattedMessage id="createLanding.title.pageTitle" tagName="h1" />
        </div>
        <div style={{ marginTop: -15, fontSize: 10 }}>
          <FormattedMessage id="createLanding.tip.can" tagName="p" />
        </div>
        {pageTitles(
          ref,
          formFields,
          inputHandler,
          selectLanguages,
          changeLanguagesValue,
          errorField
        )} */}
        {/* REGISTRATION WINDOW */}
        <div className={s.textTitle} style={{ marginTop: 46, fontWeight: 600 }}>
          <FormattedMessage
            id="createLanding.title.registration"
            tagName="h1"
          />
        </div>
        <div style={{ marginTop: -15, fontSize: 10 }}>
          <FormattedMessage id="createLanding.tip.cannot" tagName="p" />
        </div>
        {registrationWindow(
          ref,
          formFields,
          inputHandler,
          selectLanguages,
          changeLanguagesValue,
          errorField
        )}
        {/* REGISTRATION ERRORS */}
        <div className={s.textTitle} style={{ marginTop: 46, fontWeight: 600 }}>
          <FormattedMessage
            id="createLanding.title.registerErrors"
            tagName="h1"
          />
        </div>
        <div style={{ marginTop: -15, fontSize: 10 }}>
          <FormattedMessage id="createLanding.tip.cannot" tagName="p" />
        </div>
        {registrationErrors(
          ref,
          formFields,
          inputHandler,
          selectLanguages,
          changeLanguagesValue,
          errorField
        )}
        {/* REGISTRATION SUCCESS MESSAGE */}
        <div
          className={s.textTitle}
          style={{ fontWeight: 600, marginTop: 46 }}
          ref={errorField === "textBlockSuccessMsg" ? ref : null}
        >
          <FormattedMessage
            id="createLanding.title.successRegisterationMsg"
            tagName="h1"
          />
        </div>
        <InputComponent
          element={formFields?.textBlockSuccessMsg}
          inputHandler={inputHandler}
          selectLanguages={selectLanguages}
          selectLanguagesElement={"textBlockSuccessMsg"}
          changeLanguagesValue={changeLanguagesValue}
          formattedMessageInputId={
            "createLanding.title.successRegisterationMsg"
          }
          errorField={errorField}
          requiredValue={true}
          tooltipImage={images.success}
          tooltipPosition="top-start"
        />
        {/* CONFIRM BUTTON */}
        <br />
        <div
          style={{
            display: "flex",
            paddingBottom: 24,
            justifyContent: "center",
            marginTop: 46,
          }}
        >
          <button
            style={{
              height: 50,
              fontSize: 18,
              fontWeight: 500,
              background: `${isHOGDomain() && "#846414"}`,
            }}
            onClick={() =>
              handleSubmit(
                editId,
                selectLanguages,
                formFields,
                landingData,
                photoArrSponsorlogo,
                photoArrOrganizerlogo,
                photoArrPartnerlogo,
                uploadData,
                goToUrl,
                dispatch,
                showNotificationModal,
                addSpinner,
                removeSpinner,
                ServerAnswerSuccess,
                domainSelection,
                setErrors,
                errors,
                setErrorField,
                scrollToErrorField
              )
            }
          >
            {editId ? "Edit Landing" : "Create Landing"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default CreateLanding;

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
//     alignItems: "center",
//     alignContent: "center",
//     textAlign: "center",
//     backgroundColor: "#f5f5f9",
//     color: "rgba(0, 0, 0, 0.87)",
//     maxWidth: 700,
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9",
//   },
// }))(Tooltip);
