import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import { API, API_ROUTER } from "../../api/index";
import Paginator from "../../comon/Paginator";

import s from "../../comon/ComonAdmin.module.scss";
//обнуляющие стили
import "../../comon/_null_style.css";

//компоненты, из которых состоит страница
import ProfilePanel from "../../components/AdminComponents/ProfilePanelComponent/index";
import HeaderAdmin from "../../components/AdminComponents/header-admin";
import Item from "../../components/AdminComponents/itemComponent/itemComponent";
import InfoPanel from "../../components/AdminComponents/InfoPanel/index";
import { addSpinner, removeSpinner } from "../../redux/actions";

//const
import { pageInfo } from "./const";
import {
  profileInfo,
  chatInfo,
  itemConstArrayLandings,
  btnLandingsObj,
  setPaginatorDate,
} from "../../comon/comonConst";
import { FormattedMessage, useIntl } from "react-intl";

const Landings = (props) => {
  const limit = 10;
  const [itemArray, setItemArray] = useState(itemConstArrayLandings);
  const [pages, setPages] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [landingNameFilter, setLandingNameFilter] = useState("");
  const [landingDomainFilter, setLandingDomainFilter] = useState("");

  const dispatch = useDispatch();

  const getLandings = (page = 1, limit = 10) => {
    dispatch(addSpinner());
    const data = {
      page: page,
      limit: limit,
    };

    const urlParams = {};

    if (landingNameFilter) {
      urlParams["name"] = landingNameFilter;
    }

    if (landingDomainFilter) {
      urlParams["domain"] = landingDomainFilter;
    }
    let params = {
      ...API_ROUTER.landing.getDataLandings,
      pathKeys: {
        ...data,
      },
      urlParams,
    };
    API.request(params)
      .then((res) => {
        setItemArray(res.landings || itemConstArrayLandings);

        setPaginatorDate(
          res.paginator.lastPage,
          res.paginator.currentPage,
          setPagesCount,
          setPages,
          setCurrentPage
        );

        dispatch(removeSpinner());
      })
      .catch((err) => {
        console.log(err);
        dispatch(removeSpinner());
      });
  };

  useEffect(() => {
    getLandings(sessionStorage.getItem("landingsPage") || 1, limit);
  }, [landingNameFilter, landingDomainFilter]);

  return (
    <div className={s.evensPageContainer}>
      <ProfilePanel pageInfo={pageInfo} profileInfo={profileInfo} />
      <ContentContainer MainBlock={true} MainCreateBlock={null}>
        <HeaderAdmin
          pageName={pageInfo.pageName}
          toROUTER={pageInfo.toROUTER}
          addBtn={pageInfo.addBtn}
          btnInfo={pageInfo?.btnInfo}
        />

        <div className={s.row}>
          <div className={s.inputContainer}>
            <div className={s.titleInput}>
              <FormattedMessage id="landings.search.byName" />
            </div>
            <input
              type="text"
              placeholder={useIntl().formatMessage({
                id: "landings.search.byName.placeholder",
              })}
              value={landingNameFilter}
              onChange={(e) => setLandingNameFilter(e.target.value)}
            />
          </div>
          <div className={s.inputContainer}>
            <div className={s.titleInput}>
              <FormattedMessage id="landings.search.byDomain" />
            </div>
            <input
              type="text"
              placeholder={useIntl().formatMessage({
                id: "landings.search.byDomain.placeholder",
              })}
              value={landingDomainFilter}
              onChange={(e) => setLandingDomainFilter(e.target.value)}
            />
          </div>
        </div>

        <div className={s.mainScrollContainer}>
          <div className={s.scrollContainer}>
            {itemArray?.map((u, i) => (
              <Item
                key={i}
                id={u.id}
                name={u.landingName}
                status={true}
                startedAt={u.active_at}
                endedAt={u.ended_at}
                btnObj={btnLandingsObj}
                hiddenBtn={btnLandingsObj.hiddenBtn}
                domain={u.domain}
                //Пока не приходят данные с сервера я сделал так
                pro={pageInfo.pro}
                live={pageInfo.live}
              />
            ))}
            {!itemArray?.length && (
              <div className={s.center}>
                <FormattedMessage id="teams.find.noResults" />
              </div>
            )}
          </div>
          {!!pagesCount && (
            <Paginator
              limit={limit}
              pages={pages}
              getFn={getLandings}
              pagesCount={pagesCount}
              currentPage={currentPage}
              component="landingsPage"
            />
          )}
        </div>
      </ContentContainer>
      {/*{!!chatInfo && (*/}
      {/*    <InfoPanel chatInfo={chatInfo} />*/}
      {/*)}*/}
    </div>
  );
};
export default Landings;
