export function isFieldEmpty(value: string) {
  if (!value) {
    return "This field is required";
  }
  //  else if (value?.length < 2) {
  //   return "At least 2 characters";
  // }
  else {
    return "";
  }
}
