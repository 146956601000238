import React from 'react'

import ProfilePanel from '../../components/AdminComponents/ProfilePanelComponent'
import ContentContainer from '../../components/ContentContainer/ContentContainer'
import { profileInfo } from '../../comon/comonConst'
import GiveAccessForm from './giveAccessForm';
import GiveOffStatusForm from './giveOffStatusForm';

import logo from '../../img/MainCreateBlock/Upload_Logo.png';
import style from '../../comon/ComonAdmin.module.scss';
import s from '../../components/AdminComponents/CreateEvent/createEvent.module.scss';

export const pageInfo = {
    logo: logo,
}

const AccessPage = () => {
    return (
        <div className={style.evensPageContainer}>
            <ProfilePanel
                pageInfo={pageInfo}
                profileInfo={profileInfo} />
            <ContentContainer
                MainBlock={null}
                MainCreateBlock={true}
            >
                <div className={s.mainBlock} style={{ marginLeft: '-65px', marginTop: 42 }}>
                    <GiveAccessForm />
                    <GiveOffStatusForm />
                </div>
            </ContentContainer>
        </div>
    )
}

export default AccessPage