import React, { useEffect, useState } from 'react'

import ProfilePanel from '../../components/AdminComponents/ProfilePanelComponent'
import ContentContainer from '../../components/ContentContainer/ContentContainer'

import style from '../../comon/ComonAdmin.module.scss';
import s from '../../components/AdminComponents/CreateEvent/createEvent.module.scss';
import { profileInfo } from '../../comon/comonConst';
import Select from 'react-select';

import logo from '../../img/MainCreateBlock/Upload_Logo.png';
import { points } from './points';

export const pageInfo = {
    logo: logo,
}

const COEFFICIENT = [
    { value: 1.0, label: 1.0 },
    { value: 1.1, label: 1.1 },
    { value: 1.2, label: 1.2 },
    { value: 1.3, label: 1.3 },
    { value: 1.4, label: 1.4 },
    { value: 1.5, label: 1.5 },
    { value: 1.6, label: 1.6 },
    { value: 1.7, label: 1.7 },
    { value: 1.8, label: 1.8 },
    { value: 1.9, label: 1.9 },
    { value: 2.0, label: 2.0 },

]

const RatingPage = () => {

    const [pointsForPlaces, setPointsForPlaces] = useState([])
    const [coefficient, setCoefficient] = useState(null)

    const createPointsForPlaces = (points) => {
        const scores = points.map((point, idx) => {
            return { name: idx + 1, value: point }
        })
        setPointsForPlaces(scores)
    }

    useEffect(() => {
        createPointsForPlaces(points)
    }, [])

    return (
        <div className={style.evensPageContainer}>
            <ProfilePanel
                pageInfo={pageInfo}
                profileInfo={profileInfo} />
            <ContentContainer
                MainBlock={null}
                MainCreateBlock={true}
            >
                <div className={s.mainBlock} style={{ marginTop: 42, marginBottom: 60, alignItems: 'flex-start' }}>
                    <div className={s.title} style={{ marginBottom: 32 }}>
                        <span style={{ fontSize: 24, fontWeight: 500 }}>Customise your rating</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'baseline', width: 210 }}>
                        <div style={{ width: 100, marginRight: 10, }}><p style={{ fontWeight: 500, fontSize: 17, textAlign: 'center' }}>Place</p></div>
                        <div style={{ width: 100 }}><p style={{ fontWeight: 500, fontSize: 17, textAlign: 'center' }}>Points</p></div>
                    </div>
                    {pointsForPlaces.map((item, idx) => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'baseline', width: 210 }}>
                                <div style={{ marginRight: 10, width: 100, textAlign: 'center' }}>{item.name}</div>
                                <div className={s.inputContainer} style={{ width: 100, marginTop: 10 }}>
                                    <div>
                                        <input
                                            type="number"
                                            value={item.value}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div style={{ marginTop: 60 }}>
                        <div className={s.selectContainer} style={{ marginTop: 0 }}>
                            <div className={s.text} style={{ marginBottom: 10 }}>
                                <span>Select coefficient</span>
                            </div>
                            <div className={s.select}>
                                <Select
                                    onChange={(e) => {
                                        setCoefficient(e)
                                    }}
                                    classNamePrefix='custom_select'
                                    options={COEFFICIENT}
                                    placeholder='Choose coefficient'
                                    isMulti={false}
                                    value={coefficient}
                                />
                            </div>
                        </div>
                        <button type='button' style={{ marginTop: 60 }} > Customise rating</button>
                    </div>

                </div>
            </ContentContainer >
        </div >
    )
}

export default RatingPage