import TextArea from "../components/textArea";
import * as images from "../images";

const textTitle = (
  ref,
  formFields,
  inputHandler,
  selectLanguages,
  changeLanguagesValue,
  errorField
) => {
  return (
    <>
      <TextArea
        ref={ref}
        element={formFields?.textBlockContent1}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockContent1"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockContent1"}
        errorField={errorField}
        tooltipImage={images.textBlockContent1}
      />
      <TextArea
        ref={ref}
        element={formFields?.textBlockContent2}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockContent2"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockContent2"}
        errorField={errorField}
        tooltipImage={images.textBlockContent2}
      />
    </>
  );
};

export default textTitle;
