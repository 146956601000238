import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { NavLink } from 'react-router-dom';

import ProfilePanel from "../../components/AdminComponents/ProfilePanelComponent";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import HeaderAdmin from "../../components/AdminComponents/header-admin";
import { profileInfo } from "../../comon/comonConst";
import s from '../../comon/ComonAdmin.module.scss';
import { ROUTER } from '../../config';
import './dashboard.css'
import { ReactComponent as Lock } from "../../img/MainBlock/lock.svg";
import Loader from "../../comon/Loader";
import { addSpinner, removeSpinner } from "../../redux/actions";


export const pageInfo = {
    pageName: <FormattedMessage id="dashboard.title.pageName" tagName="span" />,
    filter: false,
    periodContainer: false
}

export default function Dashboard() {
    const userData = useSelector(state => state.userData)
    const dispatch = useDispatch()

    return (
        <div className={s.evensPageContainer} >
            <ProfilePanel
                pageInfo={pageInfo}
                profileInfo={profileInfo} />
            <ContentContainer
                MainBlock={true}
                MainCreateBlock={null}
            >

                <HeaderAdmin
                    pageName={pageInfo.pageName}
                    toROUTER={pageInfo.toROUTER}
                    addBtn={pageInfo.addBtn}
                    periodContainer={pageInfo.periodContainer}
                    btnInfo={pageInfo?.btnInfo}
                    /* pageMenu={pageMenu} */
                    filter={pageInfo.filter}
                />
                <p style={{ marginBottom: 42, fontSize: 20 }}>{userData?.nickname}, welcome to your ultimate dashboard! You can choose actions here. If you have any questions, check our <a style={{ color: 'white', textDecoration: 'underline' }} href='https://about.passport.gg/supportpage'>support page</a> and contact our team</p>
                <div className="linksContainer">
                    <div className='linksRow'>
                        <NavLink style={{ color: 'white' }} className='dashboardLink' to={ROUTER.membersPage}>
                            <p className="linkTitle">Manage your members</p>
                            <p className="text">Manage the players from your communities. Find the players by parameters, define if they are professional players, if they are verified (KYCed) players or even if they are banned for their behavior.</p>
                        </NavLink>
                        <NavLink style={{ color: 'white' }} className='dashboardLink' to={ROUTER.rolesManagemantPage}>
                            <p className="linkTitle">Assign managers</p>
                            <p className="text">Add and manage people to your community management. Add users and assign the requried roles and rights. You can assign managers, admins and referee rights. Rights can be assigned to all events, as well as to certain tournaments and event matches</p>
                        </NavLink>
                    </div>
                    <div className='linksRow'>
                        <NavLink style={{ color: 'white' }} className='dashboardLink' to={ROUTER.createLanding}>
                            <p className="linkTitle">Create landings</p>
                            <p className="text">Create a full landing page for you. We’ve curated simple template, allowing to create professional looking landing for any esports project of yours. You can use your own URL</p>
                        </NavLink>
                        <NavLink style={{ color: 'white' }} className='dashboardLink' to={ROUTER.createCommunity}>
                            <p className="linkTitle">Create communities</p>
                            <p className="text">Create and manage communities within your organisation. Community allows to create branded environment to promote the community, attract members and partners</p>
                        </NavLink>
                    </div>
                    <div className='linksRow'>
                        <NavLink style={{ color: 'white' }} className='dashboardLink' to={ROUTER.createEvent}>
                            <p className="linkTitle">Create events</p>
                            <p className="text">Create an esports event in a few clicks for any game. Choose the event structure and details, upload logos - and start promoting your esports activities </p>
                        </NavLink>
                        <NavLink style={{ color: 'white' }} className='dashboardLink' to={ROUTER.createTournament}>
                            <p className="linkTitle">Create tournaments</p>
                            <p className="text">Running esports event has never been so easy. Forget about Sheets for registrations and paper brackets – you have everything you need built into our tournament engine</p>
                        </NavLink>
                    </div>
                    <div className='linksRow'>
                        <div style={{ color: 'white' }} className='dashboardLink'>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <p className="linkTitle">See logs</p>
                                <Lock />
                            </div>
                            <p className="text">Track all the activity within your esports activities. Our logs provide transparent and traceable data regarding all the events and actions within your communities</p>
                        </div>
                        <div style={{ color: 'white' }} className='dashboardLink'>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <p className="linkTitle">Manage matches</p>
                                <Lock />
                            </div>
                            <p className="text">Get access to the management of the matches. Update the schedule, check the history and connect with the players in a few clicks</p>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </div >
    )
}