import { formatGame } from "../../const";

export const createDirectSlots = (num) => {
    const newArr = []
    for (let i = 2; i <= num; i++) {
        newArr.push({ id: i, name: i })
    }
    return newArr
}

export const createSecondChanceSlots = (from, to) => {
    const newArr = []
    for (let i = from + 1; i <= to; i++) {
        newArr.push({ id: i, name: i })
    }
    return newArr
}

export const createSystemOptions = (options, game) => {
    if (game?.toLowerCase() === 'pubg: battlegrounds' || game?.toLowerCase() === 'pubg mobile' || game?.toLowerCase() === 'apex legends' || game?.toLowerCase() === 'fortnite' || game?.toLowerCase() === 'free fire' || game?.toLowerCase() === 'cod warzone') {
        return options.filter(option => option.name === 'Battle-royale (FFA)')
    } else {
        return options
    }
}

export const createFormat = (game, system) => {
    let options
    if (game?.toLowerCase() === 'pubg: battlegrounds' || game?.toLowerCase() === 'pubg mobile') {
        options = [
            { id: 1, name: 'solo', backName: 1 },
            { id: 2, name: 'duo', backName: 2 },
            { id: 3, name: 'squad', backName: 4 }
        ]
        return options
    }
    if (game?.toLowerCase() === 'apex legends') {
        options = [
            { id: 1, name: 'duo', backName: 2 },
            { id: 2, name: 'trio', backName: 3 },
        ]
        return options
    }
    if (game?.toLowerCase() === 'fortnite') {
        options = [
            { id: 1, name: 'solo', backName: 1 },
            { id: 2, name: 'duo', backName: 2 },
        ]
        return options
    }
    if (game?.toLowerCase() === 'free fire max') {
        options = [
            { id: 1, name: 'solo', backName: 1 },
            { id: 2, name: 'squad', backName: 4 }
        ]
        return options
    }
    if (game?.toLowerCase() === 'cod warzone') {
        options = [
            { id: 1, name: 'solo', backName: 1 },
            { id: 2, name: 'duo', backName: 2 },
            { id: 3, name: 'trio', backName: 3 },
            { id: 4, name: 'squad', backName: 4 }
        ]
        return options
    }
    if (system === 'Battle-royale (FFA)') {
        options = [
            { id: 1, name: 'solo', backName: 1 },
            { id: 2, name: 'duo', backName: 2 },
            { id: 3, name: 'trio', backName: 3 },
            { id: 4, name: 'squad', backName: 4 }
        ]
        return options
    }
    else {
        return formatGame
    }
}

export const checknumOfParticipantsInGroup = (game, format, numOfParticipantsInGroup) => {
    if (game?.toLowerCase() === 'apex legends' && format === 'duo' && numOfParticipantsInGroup > 30) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 30
        </p>
    }
    if (game?.toLowerCase() === 'apex legends' && format === 'trio' && numOfParticipantsInGroup > 20) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 20
        </p>
    }
    if (game?.toLowerCase() === 'fortnite' && format === 'solo' && numOfParticipantsInGroup > 100) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 100
        </p>
    }
    if (game?.toLowerCase() === 'fortnite' && format === 'duo' && numOfParticipantsInGroup > 50) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 50
        </p>
    }
    if (game?.toLowerCase() === 'cod warzone' && format === 'solo' && numOfParticipantsInGroup > 140) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 140
        </p>
    }
    if (game?.toLowerCase() === 'cod warzone' && format === 'duo' && numOfParticipantsInGroup > 70) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 70
        </p>
    }
    if (game?.toLowerCase() === 'cod warzone' && format === 'trio' && numOfParticipantsInGroup > 46) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 46
        </p>
    }
    if (game?.toLowerCase() === 'cod warzone' && format === 'squad' && numOfParticipantsInGroup > 33) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 33
        </p>
    }
    if ((game?.toLowerCase() === 'pubg: battlegrounds' || game?.toLowerCase() === 'pubg mobile') && format === 'solo' && numOfParticipantsInGroup > 100) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 100
        </p>
    }
    if ((game?.toLowerCase() === 'pubg: battlegrounds' || game?.toLowerCase() === 'pubg mobile') && format === 'duo' && numOfParticipantsInGroup > 50) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 50
        </p>
    }
    if ((game?.toLowerCase() === 'pubg: battlegrounds' || game?.toLowerCase() === 'pubg mobile') && format === 'squad' && numOfParticipantsInGroup > 25) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 25
        </p>
    }
    if (game?.toLowerCase() === 'free fire max' && format === 'solo' && numOfParticipantsInGroup > 50) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 50
        </p>
    }
    if (game?.toLowerCase() === 'free fire max' && format === 'squad' && numOfParticipantsInGroup > 12) {
        return <p style={{ color: 'red' }}>
            Value must not be bigger than 12
        </p>
    }
}

export const createStages = (numOfStages, setNumOfMapsForStage) => {
    const maps = []
    for (let i = 1; i <= numOfStages; i++) {
        maps.push(3)
    }
    setNumOfMapsForStage(maps)
}

export const createScoresForPlaces = (numOfParticipantsInGroup, setScoresForPlace) => {
    const scores = []
    for (let i = 1; i <= numOfParticipantsInGroup; i++) {
        scores.push({ name: i, value: '' })
    }
    setScoresForPlace(scores)
}
