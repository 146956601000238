export const changeSelectValue = (value, fn, name) => {
    fn(value);
}

export const checkedForm = (e, setSubmitErr) => {
    e.target.value ? setSubmitErr(false) : setSubmitErr(true);
}

export const addInputs = (value, fn) => {
    let newValue = [...value];
    newValue.push({ name: "", title: "" });
    fn(newValue);
}

export const handleDelete = (idx, array, fn) => {
    const copiedArr = [...array]
    copiedArr.splice(idx, 1);
    fn(copiedArr)
}

export const onChangeDudleInput = (e, id, type, fn, data, checkFn) => {
    let value = e.target.value;
    value.length < 2 ? checkFn(true) : checkFn(false)
    let newValue = [...data];
    if (type === 'name') {
        newValue[id].name = value;
    } else {
        newValue[id].title = value;
    }
    fn(newValue);
}
