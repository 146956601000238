import TextArea from "../components/textArea";
import * as images from "../images";

const aboutLanding = (
  ref,
  formFields,
  inputHandler,
  selectLanguages,
  changeLanguagesValue,
  errorField
) => {
  return (
    <>
      {" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout1}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout1"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout1"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout1}
      />
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout2}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout2"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout2"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout2}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout3}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout3"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout3"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout3}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout4}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout4"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout4"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout4}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout5}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout5"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout5"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout5}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout6}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout6"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout6"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout6}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout7}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout7"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout7"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout7}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout8}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout8"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout8"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout8}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout9}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout9"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout9"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout9}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout10}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout10"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout10"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout10}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout11}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout11"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout11"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout11}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout12}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout12"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout12"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout12}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout13}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout13"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout13"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout13}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout14}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout14"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout14"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout14}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout15}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout15"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout15"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout15}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout16}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout16"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout16"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout16}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout17}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout17"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout17"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout17}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout18}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout18"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout18"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout18}
      />
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout19}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout19"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout19"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout19}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout20}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout20"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout20"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout20}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout21}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout21"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout21"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout21}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout22}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout22"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout22"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout22}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout23}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout23"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout23"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout23}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout24}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout24"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout24"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout24}
      />{" "}
      <TextArea
        ref={ref}
        element={formFields?.textBlockAbout25}
        inputHandler={inputHandler}
        selectLanguages={selectLanguages}
        selectLanguagesElement={"textBlockAbout25"}
        changeLanguagesValue={changeLanguagesValue}
        formattedMessageInputId={"createLanding.title.textBlockAbout25"}
        errorField={errorField}
        tooltipImage={images.textBlockAbout25}
      />
    </>
  );
};

export default aboutLanding;
